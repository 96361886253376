import {
  BOMB_IMAGES,
  DRIVER_FILE,
  FRAME_RATE,
  KEY_CODES,
  LANE_COORDINATES,
  TARGET_BLURRED_FILE,
  BOARD_HEIGHT,
  DIVIDER_HEIGHT,
} from "./constants";
import { hideElement, showElement } from "./utils";

/**
 * @class Game
 * @classdesc Used to run the Gearshift Fellowship game   */
export class Game {
  /**
   * Constructs a new instance of the game class
   * @param {JsPsych} jsPsych jsPsych instance being used to run the task
   * @param {Object} metadata The metadata JSON parameters used across the experiment
   * @param {Score} score The Score instance used to keep track of the user's score within the mission
   * @param {Object} trialConfig The JSON parameters specific to this
   */
  constructor(jsPsych, metadata, score, trialConfig) {
    /**
     * @type {JsPsych}
     * @description jsPsych instance being used to run the task
     */
    this.jsPsych = jsPsych;

    /**
     * @type {Object}
     * @description  Metadata level parameters used across the experiment
     */
    this.metadata = metadata;

    /**
     * @type {Score}
     * @description  Metadata level parameters used across the experiment
     */
    this.score = score;

    /**
     * @type {Object}
     * @description  Trial level parameters used for this specific trial
     */
    this.trialConfig = trialConfig;

    /** HTML Divs */

    this.parentWrapper; // The outermost wrapper of the game
    this.header; // The div containing all header elements
    this.headerMission; // The div displaying the current mission number
    this.headerProgress; // The div displaying the current progress
    this.headerReward; // The div displaying the current user's reward
    this.missionRewardText; // The current mission's reward
    this.headerSign; // The div containing the license
    this.stimulusImage; // The license image
    this.headerBomb; // The div containing the bomb image
    this.headerTruck; // The div containing the truck image
    this.headerLives; // The div displaying the current user's lives
    this.missionLivesText; // The current mission's lives
    this.gameWrapper; // The wrapper div for the game
    this.gameBoard; // The game board itself
    this.driverCar; // The driver's car
    this.backDriverCar; // The back of the driver's car
    this.driverImage; // The image of the driver car
    this.driverScore; // The div containing the score that is displayed over the driver
    this.targetCar; // The target car
    this.backTargetCar; // The back of the target car
    this.targetImage; // The image of the target car
    this.allHighwayDividers; // The div containing the highway dividers
    this.highwayPointer; // The specific point of the highway
    this.footer; // The div containing all footer elements
    this.footerFuel; // The div containing the user's fuel level
    this.gasNeedle; // The image of the needle used for the gas gauge
    this.footerAgent; // The div containing the agent messages
    this.agentImage; // The agent image
    this.footerSpeed; // The div containing the user's speed
    this.speedNeedle; // The image of the needle used for the speed gauge

    /** Animation logic */

    this.renderGame = true; // Whether or not to render the game
    this.playPackageAnimation = false; // Whether or not to render the package animation
    this.moveTargetCarCheck = false; // Whether or not to move the target car

    // Coordinate Classes (top, left, bottom)
    // TODO: These should just be the number, only add px when animating the style
    this.targetCarCoordinates = { top: "0px", left: "205px" }; // The coordinates of the target car within the game board
    this.driverCarCoordinates = { bottom: "0px", left: "205px" }; // The coordinates of the the driver car within the game board
    this.highwayPointerCoordinates = { top: "0px", left: "155px" }; // The coordinates of the highway pointer within the game board

    /** Overall game logic */

    // jsPsych logic (used in trial objects, not Game)
    this.targetLaunchDelay = this.metadata.targetLaunchDelay; // The delay between the start of phase 0 and the target car appearing
    this.csi = this.trialConfig.CSI; // The CSI between the stimulus being shown and the user being able to response
    this.targetCarDisappearTimestamp = null; // The timestamp at which the target car disappeared from the screen (saved data)

    // Phase logic
    this.gamePhase = null; // The specific phase of the game currently being played
    this.phase1KeyboardListerID; // The ID of the jsPsych keyboard listener for phase 1
    this.phase1KeyboardPresses = []; // An array containing all of the user's keyboard presses during phase 1

    this.phase5KeyboardListerID;
    this.phase5KeyboardPresses = [];

    this.releaseMechanism = this.trialConfig.releaseMechanism; // Which mechanism by which we release the package for phase 3
    this.stimulusDuration = this.metadata.maxTime_stimulusDuration; // The maximum time to display the stimulus for during phase 3, release mechanism 2
    this.phase3KeyboardListerID; // The ID of the jsPsych keyboard listener for phase 3
    this.phase3IntervalID; // The ID of the interval for phase 3
    this.phase3TimeoutID; // The ID of the timeout for phase 3

    // Speeds
    this.defaultDriverCarSpeed = this.metadata.driver_speed; // The starting speed of the driver's car
    this.driverCarSpeed = this.defaultDriverCarSpeed; // The current speed of the driver's car
    this.speedIncrement = this.metadata.speedIncrement; // The speed change of the driver car per keypress (phase 1)
    this.maxDriverCarSpeed = this.metadata.maxDriverSpeed; // The maximum speed of the driver car
    this.minDriverCarSpeed = this.metadata.minDriverSpeed; // The minimum speed of the driver car
    this.driverHorizontalSpeed = this.metadata.driverHorizontalSpeed; // The horizontal speed of the driver's car
    this.targetCarSpeed = this.metadata.target_speed; // The starting (constant) speed of the target car
    this.packageSpeed = this.metadata.packageSpeed; // The speed of the package

    // Distances
    this.targetAppearance = this.metadata.target_appearance; // The position where the target car appears
    this.packageAppearance = this.trialConfig.package_appearance; // The position where the package car appears
    this.targetDistance = this.metadata.target_distance; // The max distance (pixels) inside which the user can "lock on" to the target car
    this.driverDistanceToTarget = null; // The distance between the driver and target cars
    this.packageDistanceToTarget = null; // The distance between the package and target car

    // Lane selection and score
    this.targetLane = this.trialConfig.correctKeycode; // The correct lane to select
    this.e1Lane = this.trialConfig.errorT1keycode; // The "opposite" lane to the targetLane, used for a partial correct response
    this.driverCarMoveLane = null; // The lane to which the drivers car is moving (index of laneCoordinates)
    this.emotion; // Which driver emotion image to display
    this.correctLaneChosen = null; // Whether or not the user selected the correct lane
    this.rewardPoints = this.trialConfig.rewardPoints; // How many reward points this trial is worth

    // Durations
    this.feedbackDuration = this.metadata.feedbackDuration; // How long to show the feedback of the user's response for?
    this.collisionDelay = this.metadata.collisionDelay; //how long to display collision animation for as punishment
    this.headquarterMessageDuration = this.metadata.headquarterMessageDuration;

    /* MISSION 2 SPECIFIC VARIABLES */
    // TODO: Theres several of these flags that could/should be booleans

    // For mission2, 4 and 5
    this.truckSpeed = this.trialConfig.truckSpeed; // The speed of the truck
    this.moveTruckCheck = false; // Flag for animating the truck moving
    this.moveTruckLane = null; // Which lane the truck should move to

    this.initialLaneTruck = this.trialConfig.initialLaneTruck; // Starting lane of the truck
    this.truckMoveDistance = this.metadata.truckMoveDistance; // Distance from the horizon that the truck moves to before switching to the center lane

    // Whether the truck is involved in game play, defaults to 0
    // Defaults to 0 when parameter is not present in the trial config
    this.occurrenceTruck = this.trialConfig.occurrenceTruck || 0;
    // this.occurrenceTruck = 1; // TEMP: Test truck occurrence

    // Whether the user has the option to speed past the truck
    // 0: automatic animation of truck moving into center lane
    // 1: user can speed up to prevent truck from moving into center lane
    this.avoidTruck = this.trialConfig.avoidTruck;
    // this.avoidTruck = 1; // TEMP: Test truck avoidance

    // Whether the user was able to speed up and avoid the truck
    // Defaults to 0, becomes 1 if the user speeds up
    this.preventTruck = 0;

    // Whether the user follows the truck signal
    // 0: User didn't choose a lane
    // 1: User chose a lane
    // NOTE: This is set in the missionTwo file, not here
    this.followTruckSignal = null;
    // If the truck should move after the signal appears and user doesn't followTruckSignal
    this.truckMoves = this.trialConfig.truckMoves;
    //this.truckMoves = 1; // TEMP: Test truck moving to the correct lane

    // How the truck will interact with the package if it is still on the game board
    // jerk: The truck will intercept the package
    // applicable to both Mission 2 and Mission 5
    this.truckType = this.trialConfig.truckType;

    /* MISSION 3 SPECIFIC VARIABLES */
    this.escapism = this.trialConfig.escapism;
    this.occurrenceFrontbus = this.trialConfig.occurrenceFrontbus;
    this.movePackage2CheckM3 = false;
    this.escapePackagePoints = this.trialConfig.escapePackagePoints;

    //time when frontbus appears
    this.timeOnsetFrontbus_fixed = this.trialConfig.timeOnsetFrontbus_fixed;

    //time when escapePackage appears
    this.timeOnset_escapePackage = this.trialConfig.timeOnset_escapePackage;
    //whether the escape package (package2) has a bomb
    this.bombEscapePackage = this.trialConfig.bombEscapePackage;
    //whether the target package (package1) has a bomb
    this.bombTargetPackage = this.trialConfig.bombTargetPackage;

    this.speedEscapePackage = this.trialConfig.speedEscapePackage;
    this.speedFrontbus = this.trialConfig.speedFrontbus;
    //internal variable used to determine scoring
    this.userChosenLane = null;

    this.lossFraming = this.trialConfig.lossFraming;

    /* MISSION 4 SPECIFIC VARIABLES */
    this.phase0KeyboardListerID;
    this.phase0ChosenLane = null;
    // move truck flag for custom mission4 move truck mechanism
    this.moveTruckM4Check = false;
    // an array containing all of the user's keyboard presses during phase 1
    this.phase0KeyboardPresses = [];
    // represents the lane3 or lane5 truck user chooses in the beginning to pursue
    this.chosenTargetCarM4 = null;
    this.truck1Speed = this.trialConfig.truck1Speed;
    this.truck2Speed = this.trialConfig.truck2Speed;
    // lane truck1 appears in
    this.truck1Lane = this.trialConfig.truck1Lane;
    // lane truck2 appears in
    this.truck2Lane = this.trialConfig.truck2Lane;
    // time duration before truck2 appears
    this.truck2Onset = this.trialConfig.truck2Onset;

    /* MISSION 5 SPECIFIC VARIABLES */
    //determined by user decision
    this.cooperating = null;
    //initial lane for left car
    this.carLeftLane = 2;

    //initial lane for right car
    this.carRightLane = 6;

    //initial lane for truck, 3 or 5
    this.truckLane = 3;

    this.truckMoveDistance = 450;

    this.truckFollowProposal = this.trialConfig.truckFollowProposal;

    //this.gangSpeed = 4;
    this.gangSpeed = this.trialConfig.gangSpeed;

    //temporary
    this.truckPackageLane;
    this.driverPackageLane;

    this.packageLanes = {
      packageBody1: true,
      targetCarLane2Body: true,
      packageBody3: true,
      lane4: false,
      packageBody5: true,
      targetCarLane6Body: true,
      packageBody7: true,
    };
  }

  /** Reverts the game board back to its starting position(s) */
  revertGameBoard() {
    this.driverCarMoveLane = "center";
    this.targetCarCoordinates.top = "0px";
  }

  /**
   * Shows the license plate image on the highway sign
   * NOTE: This is only run once, at the start if phase3
   * NOTE: The license is turned invisible again automatically when phase 4 starts
   */
  displayLicense() {
    this.stimulusImage.src = this.trialConfig.stimulusFile;
    showElement(this.stimulusImage);
  }

  /**
   * Renders the score on top of the driver, if applicable
   * @param {boolean} flag Whether to add or remove the score from the screen
   * @param {boolean} displayTruck Whether relates to score on driver car or truck
   */
  // TODO: add truck to initialized elements, initialize to a default position like rest of the vehicles
  displayScoreOnVehicle(flag, displayTruck) {
    const score = document.getElementById("driverScore");

    if (flag) {
      // Display the score
      // showElement(score);

      if (this.truckType === "jerk" && displayTruck) {
        const truckScore = document.getElementById("truckScore");
        truckScore.textContent = `+${this.rewardPoints}`;
        truckScore.style.color = "#512888";

        score.textContent = "+0";
        score.style.color = "#EB6123";

        showElement(truckScore);
        showElement(score);
      } else {
        if (this.correctLaneChosen) {
          score.textContent = `+${this.rewardPoints}`;
          score.style.color = "#512888";
        } else {
          score.textContent = "+0";
          score.style.color = "#EB6123";
        }
        showElement(score);
      }
    } else {
      // Hide the score
      hideElement(score);
    }
  }

  displayScoreOnVehicleM3(flag, points) {
    const score = document.getElementById("driverScore");
    if (flag) {
      // Display the score
      showElement(score);
      if (points) {
        if (points >= 0) {
          score.textContent = `+${points}`;
          score.style.color = "#512888";
        } else {
          score.textContent = `${points}`;
          score.style.color = "#EB6123";
        }
      } else {
        score.textContent = "+0";
        score.style.color = "#EB6123";
      }
    } else {
      // Hide the score
      hideElement(score);
      return;
    }

    if (this.bombEscapePackage === 1 || this.bombTargetPackage === 1) {
      const bombImage = document.createElement("img");
      bombImage.id = "bomb image";
      bombImage.src = `assets/images/hud/bomb_8.svg`;
      bombImage.alt = `package2 bomb image`;
      bombImage.style.width = "20px";
      bombImage.style.height = "20px";
      bombImage.style.transform = "rotateX(180deg)";
      bombImage.style.verticalAlign = "bottom";
      bombImage.style.position = "absolute"; // Allows positioning with z-index
      bombImage.style.zIndex = "10"; // Higher z-index means the bomb will appear in front
      bombImage.style.left = "0px"; // Adjust the position as needed
      bombImage.style.top = "0px";

      if (this.userChosenLane === 1 && this.bombEscapePackage === 1) {
        document.getElementById("package2Back").appendChild(bombImage);
      } else if (this.userChosenLane === this.targetLane && this.bombTargetPackage === 1) {
        document.getElementById("packageBack").appendChild(bombImage);
      }
    }
  }

  /**
   * Renders the driver's emotion by changing its source image
   * @param {boolean} flag Whether or not to display a custom driver emotion
   */
  displayEmotionOnDriverCar(flag) {
    this.driverImage.src = flag
      ? `assets/images/driver/${this.emotion}Driver.svg`
      : `assets/images/driver/${DRIVER_FILE}`;
  }

  displayMafiaOnTargetCar() {
    let mafiaIconSource;
    if (this.targetLane === 1 || this.targetLane === 7) {
      mafiaIconSource = "iconBrightnessMafia";
    } else if (this.targetLane === 2 || this.targetLane === 6) {
      mafiaIconSource = "iconNumberMafia";
    } else {
      mafiaIconSource = "iconLetterMafia";
    }

    const mafiaBody = document.createElement("div");
    mafiaBody.id = "mafiaBody";
    mafiaBody.style.width = "25px";
    mafiaBody.style.height = "25px";
    mafiaBody.style.position = "absolute";
    mafiaBody.style.top = "48px";

    const mafiaIcon = document.createElement("img");
    mafiaIcon.id = "mafiaIcon";
    mafiaIcon.src = `assets/images/mafiaIcons/${mafiaIconSource}.svg`;
    mafiaIcon.alt = "mafia icon";
    mafiaIcon.style.width = "25px";
    mafiaIcon.style.height = "25px";
    mafiaIcon.style.transform = "rotateX(180deg)";
    mafiaBody.appendChild(mafiaIcon);

    this.backTargetCar.appendChild(mafiaBody);
  }

  displayMission4ContextCue() {
    this.stimulusImage.src = `assets/images/contextCues/${this.trialConfig.contextCue}`;
    showElement(this.stimulusImage);
  }

  displayMission4ContextCueFeedback(correct) {
    if (correct) {
      this.stimulusImage.src = `assets/images/feedbackSignals/correctChoiceM4.png`;
    } else {
      this.stimulusImage.src = `assets/images/feedbackSignals/errorChoiceM4.png`;
    }
    showElement(this.stimulusImage);
  }

  /**
   * Displays the truck signal over the stimulus
   * TODO: Define in createStimulus, have the two images on top of each other?
   */
  displayDriverProposeLane(lane) {
    if (this.trialConfig.signalUncertainty === "low") {
      this.stimulusImage.src = `assets/images/driverProposeLane/driverProposesLane${lane}_clear.png`;
    } else {
      this.stimulusImage.src = `assets/images/driverProposeLane/driverProposesLane${lane}_noisy.png`;
    }
    showElement(this.stimulusImage);
  }

  /**
   * Adds the package to the back of the target car
   * NOTE: Run at the start of phase 2 and 3
   */
  // TODO: we should handle the package as part of the STIMULUS but right now it's pretty complicated
  showPackageOnTargetCar() {
    // Create the packageBody element
    const packageBody = document.createElement("div");
    packageBody.id = "packageBody";
    packageBody.style.width = "30px";
    packageBody.style.height = "30px";
    packageBody.style.position = "absolute";
    packageBody.style.top = "45px";

    // Create the packageImage element
    const packageImage = document.createElement("img");
    packageImage.id = "packageImage";
    packageImage.src = `assets/images/packages/${this.trialConfig.packageFile}`;
    packageImage.alt = "package image";
    packageImage.style.width = "30px";
    packageImage.style.height = "30px";
    packageImage.style.transform = "rotateX(180deg)";
    packageBody.appendChild(packageImage);

    this.backTargetCar.appendChild(packageBody);
  }

  /**
   * Displays the truck signal over the stimulus
   * TODO: Define in createStimulus, have the two images on top of each other?
   */
  displayTruckSignal() {
    this.stimulusImage.src = `assets/images/truckProposesLane/${this.trialConfig.truckSignalImage}`;
    showElement(this.stimulusImage);
  }

  /**
   * Adds the package to the desired lane
   * NOTE: Only ran once, at the start of phase 5
   */
  addPackageToLane() {
    const coordinates = LANE_COORDINATES[this.targetLane] + 5;

    // Create package element
    const packageBody = document.createElement("div");
    packageBody.id = "packageBody";
    packageBody.style.width = "30px";
    packageBody.style.height = "5px";
    packageBody.style.position = "absolute";
    packageBody.style.top = `${BOARD_HEIGHT - this.packageAppearance}px`;
    packageBody.style.left = `${coordinates}px`;
    packageBody.style.transformStyle = "preserve-3d";

    // Create packageBack element
    const packageBack = document.createElement("div");
    packageBack.id = "packageBack";
    packageBack.style.width = "30px";
    packageBack.style.height = "30px";
    packageBack.style.position = "absolute";
    packageBack.style.top = "5px";
    packageBack.style.transform = "rotateX(90deg)";
    packageBack.style.transformOrigin = "top";

    // Create the packageImage element
    const packageImage = document.createElement("img");
    packageImage.id = "packageImage";
    packageImage.src = `assets/images/packages/${this.trialConfig.packageFile}`;
    packageImage.alt = "package image";
    packageImage.style.width = "30px";
    packageImage.style.height = "30px";
    packageImage.style.transform = "rotateX(180deg)";
    packageImage.style.verticalAlign = "bottom";

    packageBack.appendChild(packageImage);
    packageBody.appendChild(packageBack);
    this.gameBoard.appendChild(packageBody);

    this.packageDistanceToTarget = this.calculatePackageDistanceFromTarget();
  }

  /**
   * Renders the animation for the package, triggered by user choosing one of the lanes
   * NOTE: This is re-rendered until the package reaches the end of the lane
   */
  packageAnimation() {
    const packageTop = parseInt(
      window.getComputedStyle(document.getElementById("packageBody")).top
    );

    // Package destination is either the bottom of the board or the truck
    let destination = BOARD_HEIGHT;
    if (
      (this.followTruckSignal === 1 || (this.followTruckSignal === 0 && this.truckMoves === 0)) &&
      this.truckType === "jerk"
    ) {
      // +5 because we want the package to "show" in front of the truck
      destination = this.truckMoveDistance + 5;
    }

    let newTop = packageTop + this.packageSpeed; // Move down by 2 pixels

    // Check if package and driverCar occupy the same space (collision!)
    const driverPosition = destination - 5;
    if (packageTop <= driverPosition) {
      if (newTop > destination - 5) {
        // Reset position so package and driver are in the same place
        newTop = destination - 5;

        // End the package animation and display the score/emotion
        this.playPackageAnimation = false;
        this.displayScoreOnVehicle(
          true,
          this.truckType === "jerk" &&
            (this.followTruckSignal === 1 ||
              (this.followTruckSignal === 0) & (this.truckMoves === 0))
        );
        this.displayEmotionOnDriverCar(true);
        this.playAudio(this.emotion);
        this.displayMafiaOnTargetCar();

        // Update the score and remove the score/emotion and the package after the feedbackDuration
        setTimeout(() => {
          this.removePackage("gameBoard");
          this.displayScoreOnVehicle(
            false,
            this.truckType === "jerk" &&
              (this.followTruckSignal === 1 ||
                (this.followTruckSignal === 0) & (this.truckMoves === 0))
          );
          this.displayEmotionOnDriverCar(false);

          // Update the score and finish the phase
          if (this.correctLaneChosen) this.score.addPackage(this.rewardPoints);
          this.jsPsych.pluginAPI.cancelKeyboardResponse(this.phase5KeyboardListerID);
          this.jsPsych.finishTrial({
            rt: null,
            packageSpeedUpKeyboardPresses: this.phase5KeyboardPresses,
            packageSpeed: this.packageSpeed,
            response: null,
          });
        }, this.feedbackDuration);
      }
    }
    // Move the package
    document.getElementById("packageBody").style.top = newTop + "px";
  }

  /**
   * Renders the animation for the package, triggered by user choosing one of the lanes
   * NOTE: This is re-rendered until the package reaches the end of the lane
   */
  packageAnimationM3() {
    const packageTop = parseInt(
      window.getComputedStyle(document.getElementById("packageBody")).top
    );

    // TODO: What's going on with all these '5'? Pull into a constant

    // Package destination is either the bottom of the board or the truck
    let destination = BOARD_HEIGHT;

    let newTop = packageTop + this.packageSpeed; // Move down by 2 pixels

    // Check if package and driverCar occupy the same space (collision!)
    const driverPosition = destination - 5;
    if (packageTop <= driverPosition) {
      if (newTop > destination - 5) {
        // Reset position so package and driver are in the same place
        newTop = destination - 5;

        // End the package animation and display the score/emotion
        this.playPackageAnimation = false;

        //determine scoring based on involvement of bomb and driver chosen lane
        //also determine emotion
        let tempScore = 0;
        this.emotion = "sad";
        if (this.bombEscapePackage === 1 && this.userChosenLane === 1) {
          if (this.lossFraming === 1) {
            tempScore -= this.escapePackagePoints;
          }
          this.score.addPackage(tempScore);
        } else if (this.bombEscapePackage === 0 && this.userChosenLane === 1) {
          tempScore += this.escapePackagePoints;
          this.score.addPackage(tempScore);
          this.emotion = "happy";
        } else if (this.bombTargetPackage === 1 && this.userChosenLane === this.targetLane) {
          if (this.lossFraming === 1) {
            tempScore -= this.rewardPoints;
          }
          this.score.addPackage(tempScore);
        } else if (this.bombTargetPackage === 0 && this.userChosenLane === this.targetLane) {
          tempScore += this.rewardPoints;
          this.score.addPackage(tempScore);
          this.emotion = "happy";
        } else if (this.userChosenLane !== 1 && this.userChosenLane !== this.targetLane) {
          if (this.lossFraming === 1) {
            tempScore -= this.rewardPoints;
          }
          if (this.userChosenLane === this.e1Lane) {
            this.emotion = "surprised";
          }
          this.score.addPackage(tempScore);
        } else {
          tempScore = null;
        }

        this.displayScoreOnVehicleM3(true, tempScore);
        this.displayEmotionOnDriverCar(true);
        this.playAudio(this.emotion);
        this.displayMafiaOnTargetCar();

        // Update the score and remove the score/emotion and the package after the feedbackDuration
        setTimeout(() => {
          //TODO: I guess we don't really need to undo these actions? finishTrial will just wipe slate clean
          this.removePackage("gameBoard");
          this.displayScoreOnVehicleM3(false);
          this.displayEmotionOnDriverCar(false);

          this.jsPsych.pluginAPI.cancelKeyboardResponse(this.phase5KeyboardListerID);
          this.jsPsych.finishTrial({
            rt: null,
            packageSpeedUpKeyboardPresses: this.phase5KeyboardPresses,
            packageSpeed: this.packageSpeed,
            response: null,
          });
        }, this.feedbackDuration);
      }
    }
    // Move the package
    document.getElementById("packageBody").style.top = newTop + "px";
  }

  specialAnimationM5(objName, speed) {
    const objTop = parseInt(window.getComputedStyle(document.getElementById(`${objName}`)).top);

    if (objTop < BOARD_HEIGHT) {
      let newTop = objTop + speed; // Move down by 2 pixels
      document.getElementById(`${objName}`).style.top = newTop + "px";
    } else if (objTop >= BOARD_HEIGHT) {
      document.getElementById(`${objName}`).style.top = "0px";
      document.getElementById(`${objName}`).style.display = "none";
      //turn animation off
      if (objName === "package2Body") {
        this.movePackage2CheckM3 = false;

        //lane 1 is no longer an option for the user once package exits highway
        //update the keyboard listener to not listen for lane 1 "x" presses
        this.jsPsych.pluginAPI.cancelKeyboardResponse(this.phase3KeyboardListerID);
        this.phase3KeyboardListerID = this.jsPsych.pluginAPI.getKeyboardResponse({
          callback_function: this.chooseLaneKeypress.bind(this),
          valid_responses: Object.keys(KEY_CODES).filter((key) => key !== "s" && key !== ";"),
          allow_held_key: false,
        });
      } else this.moveFrontbusCheckM3 = false;
    }
  }

  /**
   * NOTE: This is only run once, triggered when user presses key for respective lane in phase2
   * @param {string} location The id of the element on which the package is located
   */
  removePackage(location) {
    const packageBody = document.getElementById("packageBody");
    if (location === "targetCar") {
      this.backTargetCar.removeChild(packageBody);
    } else if (location === "gameBoard") {
      this.gameBoard.removeChild(packageBody);
    }
  }

  /**
   * Continually re-rendered until driver car moved to desired new lane
   * Activated only in phase 2
   */
  moveDriverCarLeftRight() {
    const destination = LANE_COORDINATES[this.driverCarMoveLane];
    const currentDriverLeft = parseInt(window.getComputedStyle(this.driverCar).left);

    if (currentDriverLeft - this.driverHorizontalSpeed >= destination) {
      this.driverCarCoordinates.left =
        parseInt(window.getComputedStyle(this.driverCar).left) - this.driverHorizontalSpeed + "px";
    } else if (currentDriverLeft + this.driverHorizontalSpeed <= destination) {
      this.driverCarCoordinates.left =
        parseInt(window.getComputedStyle(this.driverCar).left) + this.driverHorizontalSpeed + "px";
    } else {
      if (this.gamePhase === 7) {
        this.driverCarMoveLane = null;
        setTimeout(() => this.jsPsych.finishTrial({ rt: null, response: null }), 2000);
      } else if (this.moveTruckCheck === false || this.moveTruckCheck === undefined) {
        this.driverCarMoveLane = null;
        this.jsPsych.finishTrial({ rt: null, response: null });
      }
    }
  }

  /** Runs the crash animation and finishes the trial after a given delay */
  crashDriver() {
    // Show crash driver animation
    hideElement(this.targetCar);
    this.driverImage.src = "assets/images/driver/collisionDriver.svg";

    // Update score and finish the trial after the crash delay
    setTimeout(() => {
      this.score.addCrash();
      this.jsPsych.finishTrial({
        driveupKeyboardPresses: this.phase1KeyboardPresses,
        crashed: true,
        rt: null,
        response: null,
      });
    }, this.collisionDelay);
  }

  async playAudio(audioFile) {
    try {
      // get the preloaded audio buffer, returns promise
      const audioBuffer = await this.jsPsych.pluginAPI.getAudioBuffer(
        `assets/audio/${audioFile}.wav`
      );

      // create audioContext
      const audioContext = this.jsPsych.pluginAPI.audioContext();

      // create audio buffer source
      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;

      // connect the source
      source.connect(audioContext.destination);

      // playing the audio
      source.start(0);
    } catch (error) {
      console.error("Error playing audio:", error);
    }
  }

  /**
   * Continually re-rendered until driver car reaches target car
   * only happens in phase1 of game
   * @param {number} speed The speed at which the target car moves
   */
  moveTargetCar(speed) {
    const targetCarCurrentTop = parseInt(window.getComputedStyle(this.targetCar).top);
    const driverCarCurrentTop = parseInt(window.getComputedStyle(this.driverCar).top);
    const targetCarBottom =
      targetCarCurrentTop + parseInt(window.getComputedStyle(this.targetCar).height);
    let newTop = targetCarCurrentTop + speed; // Move down by 2 pixels

    // for phase1, stop car right before "colliding" with driver car, regardless of if player pressed space button or not
    if (this.gamePhase === 1) {
      // Check if targetCar and driverCar occupy the same space (collision!)
      if (newTop > driverCarCurrentTop) {
        // Set target exactly on top of the driver, equalize speed, and stop moving the car
        newTop = driverCarCurrentTop;
        this.moveTargetCarCheck = false;
        this.driverCarSpeed = this.defaultDriverCarSpeed;

        // Ignore any other keyboard events and crash the driver
        this.jsPsych.pluginAPI.cancelKeyboardResponse(this.phase1KeyboardListerID);
        this.crashDriver();
      }

      // Move the target car and update distance check
      this.targetCarCoordinates.top = newTop + "px";
      this.driverDistanceToTarget = this.calculateDriverDistanceToTarget();
    }
    // This is for phase 2, drive target car off highway after user makes lane change
    else {
      // Check if target car as reached BOARD_HEIGHT (off the screen!)
      if (targetCarBottom >= BOARD_HEIGHT) {
        newTop = BOARD_HEIGHT; // targetCar remains at edge of highway until end of phase
        this.targetCarDisappearTimestamp = performance.now();
        this.moveTargetCarCheck = false;
      }
      this.targetCarCoordinates.top = newTop + "px";
    }
  }

  /**
   * Continually re-rendered until driver car reaches target car
   * only happens in phase1 of game
   * @param {number} speed The speed at which the truck  moves
   */
  moveTruck(speed) {
    const truckTop = parseInt(window.getComputedStyle(document.getElementById("truckBody")).top);
    const truckLeft = parseInt(window.getComputedStyle(document.getElementById("truckBody")).left);
    // TODO: These 5's should be pulled into a variable, height of the truck image?

    // Phase 1: Truck is moving up the highway and then to the center lane
    const destination = this.moveTruckLane;
    if (this.gamePhase === 1) {
      // Make truck disappear if it's moved backwards off the highway
      if (truckTop > BOARD_HEIGHT - 5) {
        this.gameBoard.removeChild(document.getElementById("truckBody"));
        this.moveTruckCheck = false;
      }
      // Truck is moving forward up the highway
      else if (truckTop > this.truckMoveDistance && truckTop <= BOARD_HEIGHT - 5) {
        let newTop = truckTop - speed;
        if (newTop < this.truckMoveDistance) newTop = this.truckMoveDistance;
        document.getElementById("truckBody").style.top = newTop + "px";
      }
      // Truck has reached its forward distance on the lane, move to center lane
      else if (truckTop === this.truckMoveDistance) {
        this.jsPsych.pluginAPI.cancelKeyboardResponse(this.phase1KeyboardListerID); // cancel speedup keypress possibility

        // TODO: This move left/right logic is used a bunch in function, pull it out
        if (truckLeft - 1 >= destination) {
          document.getElementById("truckBody").style.left = truckLeft - 1 + "px";
        } else if (truckLeft + 1 <= destination) {
          document.getElementById("truckBody").style.left = truckLeft + 1 + "px";
        }
      }
      // Truck has reached destination in the center lane, stop moving and finish the trial
      if (truckTop === this.truckMoveDistance && truckLeft === LANE_COORDINATES["center"]) {
        this.moveTruckCheck = false;
        this.jsPsych.finishTrial({
          driveupKeyboardPresses: this.phase1KeyboardPresses,
          rt: null,
          response: null,
        });
      }
    }

    // Phase 1.2: Truck moves down the highway from the center lane off the right side
    else if (this.gamePhase === 1.2) {
      // Move truck to the to the right lane
      if (truckTop === this.truckMoveDistance && truckLeft !== destination) {
        if (truckLeft - 1 >= destination) {
          document.getElementById("truckBody").style.left = truckLeft - 1 + "px";
        } else if (truckLeft + 1 <= destination) {
          document.getElementById("truckBody").style.left = truckLeft + 1 + "px";
        }
      }
      // Truck is in right lane, move it down the highway
      else if (truckTop <= BOARD_HEIGHT - 5) {
        let newTop = truckTop + speed;
        document.getElementById("truckBody").style.top = newTop + "px";
      }

      // Truck has moved off the bottom of the highway, hide it
      if (truckTop > BOARD_HEIGHT - 5) {
        this.gameBoard.removeChild(document.getElementById("truckBody"));
        this.moveTruckCheck = false;
        this.jsPsych.finishTrial({ rt: null, response: null });
      }
    }

    // Truck is still on the game board in later phases, handle interaction with the package
    else {
      // Truck is a jerk - move to intercept the package
      if (this.truckType === "jerk") {
        if (truckLeft - 1 >= destination) {
          document.getElementById("truckBody").style.left = truckLeft - 1 + "px";
        } else if (truckLeft + 1 <= destination) {
          document.getElementById("truckBody").style.left = truckLeft + 1 + "px";
        } else {
          // Truck is in the desired lane, end animation
          this.moveTruckCheck = false;
        }
      }
      // Truck is not a jerk, exit the highway
      else {
        if (truckTop <= BOARD_HEIGHT - 5) {
          let newTop = truckTop + speed;
          document.getElementById("truckBody").style.top = newTop + "px";
        }
        if (truckTop > BOARD_HEIGHT - 5) {
          this.gameBoard.removeChild(document.getElementById("truckBody"));
          this.moveTruckCheck = false;
        }
      }
    }
  }

  moveTruckM5(speed) {
    const truckTop = parseInt(window.getComputedStyle(document.getElementById("truckBody")).top);
    const truckLeft = parseInt(window.getComputedStyle(document.getElementById("truckBody")).left);

    const destination = LANE_COORDINATES[this.moveTruckLane];

    //All truck movements for phase0.1, where truck is driving up the highway, from left to center
    if (this.gamePhase === 0.2) {
      //Truck is moving in opposite direction, off highway, make truck disappear is off the highway
      if (truckTop > BOARD_HEIGHT - 5) {
        this.gameBoard.removeChild(document.getElementById("truckBody"));
        this.moveTruckCheck = false;
      }

      //For truck moving up the highway. Move at truck speed
      else if (truckTop > this.truckMoveDistance && truckTop <= BOARD_HEIGHT - 5) {
        let newTop = truckTop - speed; // Move up by 2 pixels
        if (newTop < this.truckMoveDistance) {
          newTop = this.truckMoveDistance;
        }
        document.getElementById("truckBody").style.top = newTop + "px";
      }
      //Once truck top reaches 500, time to move either right or left to center lane
      else if (truckTop === this.truckMoveDistance) {
        //cancel speedup keypress possibility
        this.jsPsych.pluginAPI.cancelKeyboardResponse(this.phase1KeyboardListerID);
        if (truckLeft - 1 >= destination) {
          document.getElementById("truckBody").style.left = truckLeft - 1 + "px";
        } else if (truckLeft + 1 <= destination) {
          document.getElementById("truckBody").style.left = truckLeft + 1 + "px";
        }
      }

      //reached final destination of center lane. End this movement
      if (truckTop === this.truckMoveDistance && truckLeft === 205) {
        this.moveTruckCheck = false;
        this.jsPsych.finishTrial({ rt: null, response: null });
      }
    }
    //moving truck left/right to other lane or move truck down, off highway
    else {
      //move truck left/right
      if (truckLeft - 1 >= destination) {
        document.getElementById("truckBody").style.left = truckLeft - 1 + "px";
      } else if (truckLeft + 1 <= destination) {
        document.getElementById("truckBody").style.left = truckLeft + 1 + "px";
      }
      // }
      else {
        //reached desired lane.
        this.moveTruckCheck = false;
      }
    }
  }

  packageAnimationM5() {
    //Check here to see if all packages have reached end of their respective lanes
    const animationDone = Object.values(this.packageLanes).every((value) => !value);
    //if animation complete
    if (animationDone) {
      this.score.addPackage(this.determineScoreM5());

      //prevent further rendering of package animation function
      this.playPackageAnimation = false;

      for (const key in this.packageLanes) {
        if (
          key !== Object.keys(this.packageLanes)[this.driverPackageLane - 1] &&
          key !== Object.keys(this.packageLanes)[this.moveTruckLane - 1] &&
          key !== "lane4"
        ) {
          hideElement(document.getElementById(`${key}Score`));
        }
      }

      setTimeout(() => {
        this.jsPsych.pluginAPI.cancelKeyboardResponse(this.phase5KeyboardListerID);
        this.jsPsych.finishTrial({
          rt: null,
          packageSpeedUpKeyboardPresses: this.phase5KeyboardPresses,
          packageSpeed: this.packageSpeed,
          response: null,
        });
      }, 1000);
    }

    let index = 1;

    for (const key in this.packageLanes) {
      if (this.packageLanes[key]) {
        const packageTop = parseInt(window.getComputedStyle(document.getElementById(`${key}`)).top);

        //let newTop = packageTop + this.packageSpeed; // Move down by 2 pixels
        let driverPosition = BOARD_HEIGHT - 5;

        //package for truck will terminate earlier, due to forward position of truck
        if (this.moveTruckLane === index) {
          driverPosition = BOARD_HEIGHT - 95;
        }

        if (packageTop <= driverPosition) {
          let newTop;
          if (key === "targetCarLane2Body" || key === "targetCarLane6Body") {
            newTop = packageTop + this.gangSpeed; // Move down by packageSpeed pixel speed
          } else {
            newTop = packageTop + this.packageSpeed; // Move down by packageSpeed pixel speed
          }

          //current package has reached destination position
          if (newTop > driverPosition) {
            // Reset position so package and driver are in the same place
            newTop = driverPosition;
            //set to false to prevent package from moving in future iterations
            this.packageLanes[key] = false;

            //set truck package flag to done if relevant
            if (this.moveTruckLane === index) {
              if (index === 2 || index === 6) {
                //if lane has targetCar, not package, show truck collision animation and hide targetCar
                hideElement(document.getElementById(`${key}`));
                // TODO: show collisionTruck when image is made available
                document.getElementById("truckImage").src = "assets/images/truck/crashTruck.svg";
              }
              // if current lane not truck package lane and is driver package lane, consider package animation done
            } else if (this.driverPackageLane === index) {
              if (index === 2 || index === 6) {
                //if lane has targetCar, not package, show driver collision animation and hide targetCar
                hideElement(document.getElementById(`${key}`));
                // Show crash driver animation
                this.driverImage.src = "assets/images/driver/collisionDriver.svg";
              }
            }
          }
          document.getElementById(`${key}`).style.top = newTop + "px";
        }
      }
      index += 1;
    }
  }

  determineScoreM5() {
    let totalScore = 0;
    //driver and truck both go for the left side package

    if (this.cooperating) {
      if (
        Math.abs(this.driverPackageLane - this.truckPackageLane) === 2 &&
        this.driverPackageLane < 4 &&
        this.truckPackageLane < 4
      ) {
        //the driver only gets the score of the lane they chose
        if (this.driverPackageLane === 1) {
          totalScore += this.trialConfig.carLeftNumberL;
        } else if (this.driverPackageLane === 3) {
          totalScore += this.trialConfig.carLeftNumberR;
        }
        totalScore += this.packageLanes[Object.keys(this.packageLanes)[this.driverPackageLane - 1]];
      }
      //driver and truck both go for the right side package
      else if (
        Math.abs(this.driverPackageLane - this.truckPackageLane) === 2 &&
        this.driverPackageLane > 4 &&
        this.truckPackageLane > 4
      ) {
        if (this.driverPackageLane === 5) {
          totalScore += this.trialConfig.carRightNumberL;
        } else if (this.driverPackageLane === 7) {
          totalScore += this.trialConfig.carRightNumberR;
        }
      }
      //driver and truck did not go for same side
      else {
        if (this.driverPackageLane !== this.truckPackageLane) {
          //hide package points
          hideElement(
            document.getElementById(
              `${Object.keys(this.packageLanes)[this.driverPackageLane - 1]}Score`
            )
          );
          hideElement(
            document.getElementById(
              `${Object.keys(this.packageLanes)[this.truckPackageLane - 1]}Score`
            )
          );
          const driverScore = document.getElementById("driverScore");
          driverScore.style.color = "#EB6123";
          driverScore.style.fontSize = "32px";
          driverScore.style.paddingBottom = "20px";

          const truckScore = document.createElement("div");
          truckScore.id = "truckScore";
          truckScore.className = "driverScore";
          truckScore.style.color = "#EB6123";
          truckScore.style.fontSize = "32px";
          truckScore.style.paddingBottom = "20px";

          //driver is associated with carLeftNumber reward
          if (this.driverPackageLane < 4) {
            //add point directly to driverCar only if it didnt collide
            if (this.driverPackageLane !== 2 && this.driverPackageLane !== 6) {
              driverScore.textContent = `+${this.trialConfig.carLeftNumber}`;
              showElement(driverScore);
              totalScore += this.trialConfig.carLeftNumber;

              if (this.truckPackageLane !== 2 && this.truckPackageLane !== 6) {
                truckScore.textContent = `+${this.trialConfig.carRightNumber}`;
                document.getElementById("truckBack").appendChild(truckScore);
              }
            }
            //driver is associated with carRightNumber reward
          } else {
            //add point directly to driverCar only if it didnt collide
            if (this.driverPackageLane !== 2 && this.driverPackageLane !== 6) {
              driverScore.textContent = `+${this.trialConfig.carRightNumber}`;
              showElement(driverScore);
              totalScore += this.trialConfig.carRightNumber;

              if (this.truckPackageLane !== 2 && this.truckPackageLane !== 6) {
                truckScore.textContent = `+${this.trialConfig.carLeftNumber}`;
                document.getElementById("truckBack").appendChild(truckScore);
              }
            }
          }
        }
      }
    }
    //no cooperation scenario
    else {
      if (this.driverPackageLane === 1 || this.driverPackageLane === 3)
        totalScore += this.trialConfig.carLeftNumber;
      if (this.driverPackageLane === 5 || this.driverPackageLane === 7)
        totalScore += this.trialConfig.carRightNumber;

      hideElement(
        document.getElementById(
          `${Object.keys(this.packageLanes)[this.driverPackageLane - 1]}Score`
        )
      );

      if (
        this.driverPackageLane === 1 ||
        this.driverPackageLane === 3 ||
        this.driverPackageLane === 5 ||
        this.driverPackageLane === 7
      ) {
        const score = document.getElementById("driverScore");
        score.textContent = `+${totalScore}`;
        score.style.color = "#EB6123";
        score.style.fontSize = "32px";
        score.style.paddingBottom = "20px";
        showElement(score);
      }
    }

    return totalScore;
  }

  // Special move target car animation for mission 4
  // There are two target cars in this animation
  // Both moving down the highway. One will eventually make contact with the driver car.
  // The other will just move off the highway.
  moveTargetCarM4(speed) {
    const leftTargetCarCurrentTop = parseInt(
      window.getComputedStyle(document.getElementById("targetCarLane3Body")).top
    );
    const driverCarCurrentTop = parseInt(window.getComputedStyle(this.driverCar).top);

    let newTop = leftTargetCarCurrentTop + speed; // Move down by 2 pixels
    if (newTop > driverCarCurrentTop) {
      // collision happened
      newTop = driverCarCurrentTop;

      //hide both  target cars
      document.getElementById("targetCarLane3Body").style.display = "none";
      document.getElementById("targetCarLane5Body").style.display = "none";

      //only show collision driver animation
      const driverImage = document.getElementById("driverImage");
      driverImage.src = "assets/images/driver/collisionDriver.svg";

      this.moveTargetCarCheck = false;
      // Switch to phase 3, meaning no other buttons can be pressed. game is over
      this.gamePhase = 3;
      this.driverCarSpeed = this.defaultDriverCarSpeed;
      this.proceedGame = false;
      // this.driverDistanceToTarget = newTop;
      setTimeout(() => {
        this.score.addCrash();
        this.jsPsych.finishTrial({ crashed: true, rt: null, response: null });
      }, this.collisionDelay);
    }

    document.getElementById("targetCarLane3Body").style.top = newTop + "px";
    document.getElementById("targetCarLane5Body").style.top = newTop + "px";
  }

  moveTruckM4(speed) {
    const truckTop = parseInt(window.getComputedStyle(document.getElementById("truckBody")).top);

    if (truckTop > 0) {
      let newTop = truckTop - speed; // Move up by 2 pixels
      document.getElementById("truckBody").style.top = newTop + "px";
    } else if (truckTop < 0) {
      document.getElementById("truckBody").style.top = "0px";
      document.getElementById("truckBody").style.display = "none";
      this.moveTruckM4Check = false;
      if (this.gamePhase !== 3) this.jsPsych.finishTrial();
    }
  }

  /**
   * Checks the user's chosen lane against the correct response
   * @param {number} choice The lane the user chose to enter
   */
  checkUserChoice(choice) {
    // Whether or  not the user entered the correct lane
    this.correctLaneChosen = choice === this.targetLane;

    if (
      this.truckType === "jerk" &&
      (this.followTruckSignal === 1 || (this.followTruckSignal === 0) & (this.truckMoves === 0))
    ) {
      // Truck type is jerk - emotion is always sad
      this.emotion = "sad";
    } else {
      // The user entered the correct response
      if (choice === this.targetLane) this.emotion = "happy";
      // The user answered incorrectly but with the correct grouping ("type one error")
      else if (choice === this.e1Lane) this.emotion = "surprised";
      // The user answered incorrectly and with the incorrect grouping ("type 2 error")
      else this.emotion = "sad";
    }
  }

  /**
   * Dynamically generate a row of highway dividers
   * @param {number} top The top position of the divider
   * @returns
   */
  drawDividers(top) {
    const dividers = [];
    const height = Math.min(DIVIDER_HEIGHT, BOARD_HEIGHT - top);

    // Between lanes 1-2
    const divider12 = document.createElement("div");
    divider12.classList.add("highwayDivider");
    divider12.style.top = `${top}px`;
    divider12.style.left = "60px";
    divider12.style.height = `${height}px`;
    dividers.push(divider12);

    // Between lanes 2-3
    const divider23 = document.createElement("div");
    divider23.classList.add("highwayDivider");
    divider23.style.top = `${top}px`;
    divider23.style.left = "125px";
    divider23.style.height = `${height}px`;
    dividers.push(divider23);

    // Between lanes 3-center
    const divider34 = document.createElement("div");
    divider34.classList.add("highwayDivider");
    divider34.style.top = `${top}px`;
    divider34.style.left = "190px";
    divider34.style.height = `${height}px`;
    dividers.push(divider34);

    // Between lanes center-5
    const divider45 = document.createElement("div");
    divider45.classList.add("highwayDivider");
    divider45.style.top = `${top}px`;
    divider45.style.left = "255px";
    divider45.style.height = `${height}px`;
    dividers.push(divider45);

    // Between lanes 5-6
    const divider56 = document.createElement("div");
    divider56.classList.add("highwayDivider");
    divider56.style.top = `${top}px`;
    divider56.style.left = "320px";
    divider56.style.height = `${height}px`;
    dividers.push(divider56);

    // Between lanes 6-7
    const divider67 = document.createElement("div");
    divider67.classList.add("highwayDivider");
    divider67.style.top = `${top}px`;
    divider67.style.left = "385px";
    divider67.style.height = `${height}px`;
    dividers.push(divider67);

    return dividers;
  }

  /**
   * Dynamically generate the highway lanes
   * Continually called throughout duration of entire game
   */
  generateHighWayDividers() {
    const allDividers = [];
    let highwayPointer = parseInt(window.getComputedStyle(this.highwayPointer).top);

    // The space between the dividers is exactly as tall as the divider itself
    const dividerSpacing = DIVIDER_HEIGHT * 2;

    // Generates the dividers before the highway pointer
    let beforePointer = highwayPointer - dividerSpacing;
    while (beforePointer >= 0) {
      const dividers = this.drawDividers(beforePointer);
      allDividers.push(...dividers);
      beforePointer -= dividerSpacing;
    }

    // Generates the dividers after the highway pointer
    while (highwayPointer < BOARD_HEIGHT) {
      const dividers = this.drawDividers(highwayPointer);
      allDividers.push(...dividers);
      highwayPointer += dividerSpacing;
    }

    // Remove the current dividers and add the newly created ones
    while (this.allHighwayDividers.firstChild) {
      this.allHighwayDividers.removeChild(this.allHighwayDividers.firstChild);
    }
    allDividers.forEach((divider) => {
      this.allHighwayDividers.appendChild(divider);
    });
  }

  /**
   * Generate and move the highway lanes
   * Continually called throughout duration of entire game
   * @param {number} speed The speed at which the dividers should move
   */
  moveHighwayDividers(speed) {
    this.generateHighWayDividers();

    //if speed is 0, cars are not moving but highway needs to keep moving
    if (speed === 0) speed = 1;

    // Move the highwayPointer
    const highwayPointer = parseInt(window.getComputedStyle(this.highwayPointer).top);
    let newTop = highwayPointer + speed;
    if (newTop >= BOARD_HEIGHT) newTop = 0; // Loop pointer back to the top of the game board
    this.highwayPointerCoordinates.top = `${newTop}px`;
  }

  /** Update the coordinates of the HTML elements */
  updateCoordinates() {
    // driverCar
    this.driverCar.style.bottom = this.driverCarCoordinates.bottom;
    this.driverCar.style.left = this.driverCarCoordinates.left;

    // targetCar
    this.targetCar.style.top = this.targetCarCoordinates.top;
    this.targetCar.style.left = this.targetCarCoordinates.left;

    // highwayPointer
    this.highwayPointer.style.top = this.highwayPointerCoordinates.top;
    this.highwayPointer.style.left = this.highwayPointerCoordinates.left;
  }

  /** Main render function of the game */
  render() {
    // The game moves based on the difference between the driverCar and targetCar speeds
    const gameSpeed = this.driverCarSpeed - this.targetCarSpeed;

    // Moves the highway and other game elements
    this.moveHighwayDividers(gameSpeed);
    this.updateCoordinates();

    // Render the target car, if applicable (m4)
    if (this.moveTargetCarCheck) {
      //Unique to mission4
      if (this.gamePhase === 0.3) {
        this.moveTargetCarM4(gameSpeed);
      } else {
        this.moveTargetCar(gameSpeed);
      }
    }
    //Unique to Mission4
    if (this.moveTruckM4Check) {
      this.moveTruckM4(this.truckSpeed);
    }

    // Render the truck, if applicable
    if (this.moveTruckCheck) {
      if (this.cooperating !== null) {
        this.moveTruckM5(this.truckSpeed);
      } else {
        this.moveTruck(this.truckSpeed);
      }
    }

    // Render the driver car, if applicable
    if (this.driverCarMoveLane !== null) this.moveDriverCarLeftRight();

    // Render the package, if applicable
    if (this.playPackageAnimation) {
      if (this.escapism) {
        // For mission3
        this.packageAnimationM3();
      }
      //For mission5
      else if (this.cooperating !== null) {
        this.packageAnimationM5();
      } else {
        this.packageAnimation();
      }
    }

    // Render package2 and front bus, (Mission3 only)
    if (this.movePackage2CheckM3) this.specialAnimationM5("package2Body", this.speedEscapePackage);
    if (this.moveFrontbusCheckM3) this.specialAnimationM5("frontbusBody", this.speedFrontbus);

    if (this.renderGame) {
      setTimeout(() => {
        requestAnimationFrame(this.render.bind(this));
      }, 1000 / FRAME_RATE);
    }
  }

  /** ------------- START PHASE FUNCTIONS ------------- */

  /** Logic that's shared across all phases  */
  startPhase(phase) {
    this.gamePhase = phase;
    this.initializeGameComponents();
    this.initializeHUD();
    this.updateCoordinates();

    console.log("Starting Phase:", phase);
  }

  /** Executes phase 0 of the game */
  startPhase0() {
    this.startPhase(0);

    // The target car is hidden during the first phase
    hideElement(this.targetCar);

    // Begin rendering the game
    this.render();
  }

  /** Executes phase 1 of the game */
  startPhase1() {
    this.startPhase(1);

    // Blur the target car
    // TODO: Separate car for blurring the target car
    this.targetImage.src = TARGET_BLURRED_FILE;

    // Initialize the truck in the left lane if applicable (m2)
    if (this.occurrenceTruck === 1) {
      this.initializeTruck("left");
      this.moveTruckCheck = true;
      this.moveTruckLane = LANE_COORDINATES["center"];
    }

    // Begin animating the target car
    this.moveTargetCarCheck = true;

    // TODO: Separate event listener for ALL keyboard events
    // this code below is not shown in "startPhase1 (phase: engageTarget) in mission 2
    // in the data: the packet "engageTarget" misses the structure "driveUpKeypress" in mission 2
    // maybe the code below needs to be added into the if-stmt above (line 1413)
    this.phase1KeyboardListerID = this.jsPsych.pluginAPI.getKeyboardResponse({
      callback_function: this.driveUpKeypress.bind(this),
      allow_held_key: false,
      persist: true,
    });
  }

  /** Executes phase 2 of the game */
  startPhase2() {
    this.startPhase(2);

    this.driverCarSpeed = this.defaultDriverCarSpeed; // Reset speed to default
    this.moveTruckCheck = false; // Ensure the truck animation is off

    // Display the package
    this.showPackageOnTargetCar();

    // for mission 2 (show footerLabel when truck proposes a lane to driver in grey window)
    // for mission 3 (footer laneLabels appears when driver locks onto target car)
    if (
      this.trialConfig.agentFile === "footer_LaneLabels_M2.svg" ||
      this.trialConfig.agentFile === "footer_LaneLabels_M3.svg"
    ) {
      showElement(this.agentImage);
    }
  }

  /** Executes phase 3 of the game */
  startPhase3() {
    this.startPhase(3);

    this.phase3KeyboardListerID = this.jsPsych.pluginAPI.getKeyboardResponse({
      callback_function: this.chooseLaneKeypress.bind(this),
      valid_responses: Object.keys(KEY_CODES),
      allow_held_key: false,
    });

    if (
      this.trialConfig.agentFile === "footer_LaneLabels.svg" ||
      this.trialConfig.agentFile === "footer_LaneLabels_M2.svg" ||
      this.trialConfig.agentFile === "footer_LaneLabels_M3.svg" ||
      this.trialConfig.agentFile === "footer_LaneLabels_M4.svg" ||
      this.trialConfig.agentFile === "footer_LaneLabels_M5.svg"
    ) {
      showElement(this.agentImage);
    }
    // Display the package and the license
    this.showPackageOnTargetCar();
    this.displayLicense(true);

    // Begin the bomb countdown animation for package release mechanism 2
    if (this.releaseMechanism === 2) this.countdownBombs();
  }

  /**
   * Handles the timing and displays the animation for the bomb timer
   * NOTE: Only used for packageReleaseMechanism 2
   */
  countdownBombs() {
    // Create the bomb image
    const bombs = [...BOMB_IMAGES]; // We need a copy of the array because of .shift()
    const bombImage = document.createElement("img");
    bombImage.id = "bombImage";
    bombImage.src = bombs.shift();
    bombImage.alt = "The bomb image";
    bombImage.class = "hudImage";
    this.headerBomb.appendChild(bombImage);

    // Show the bomb and add an interval to switch the bomb images
    showElement(this.headerBomb);
    this.phase3IntervalID = setInterval(() => {
      const bomb = bombs.shift();
      if (bomb) {
        // Update bomb image
        bombImage.src = bomb;
      } else {
        // Timeout is finished - crash!
        clearInterval(this.phase3IntervalID);
        this.jsPsych.pluginAPI.cancelKeyboardResponse(this.phase3KeyboardListerID);
        this.crashDriver();
      }
    }, this.stimulusDuration / BOMB_IMAGES.length);
  }

  /** Executes phase 4 of the game */
  startPhase4(chosenLane) {
    this.startPhase(4);

    //exclusive to mission4, set targetcar to one of the 2 targetCars
    if (this.chosenTargetCarM4) document.getElementById("targetImage").src = this.chosenTargetCarM4;

    // Initialize the truck in the center lane (m2)
    // TODO: Pull this boolean check into a function - it's used in multiple places
    if (this.followTruckSignal === 1 || (this.followTruckSignal === 0 && this.truckMoves === 0)) {
      this.initializeTruck("center");
      this.moveTruckCheck = true;
      this.moveTruckLane = LANE_COORDINATES[this.targetLane];
      hideElement(this.backTargetCar);
    }

    //mission3, means driver is capturing package2, so show package2
    if (this.escapism && chosenLane === 1) {
      this.initializePackage2M3();
    }
    //mission3, show front bus if applicable
    if (this.occurrenceFrontbus) {
      this.initializeFrontBusM3();
    }

    // Check the user's choice and begin moving the drivers car
    if (!this.escapism) {
      this.checkUserChoice(chosenLane);
    } else {
      //exclusive for m3
      this.userChosenLane = chosenLane;
    }

    this.driverCarMoveLane = chosenLane;

    if (this.trialConfig.agentFile.length === 2) {
      this.agentImage.src = `assets/images/headquarter/${this.trialConfig.agentFile[1]}`;
      showElement(this.agentImage);
    } else if (
      this.trialConfig.agentFile === "footer_LaneLabels.svg" ||
      this.trialConfig.agentFile === "footer_LaneLabels_M2.svg" ||
      this.trialConfig.agentFile === "footer_LaneLabels_M3.svg"
    ) {
      showElement(this.agentImage);
    }
  }

  /** Executes phase 5 of the game */
  startPhase5(chosenLane) {
    this.startPhase(5);

    //exclusive to mission4, set targetcar to one of the 2 targetCars
    if (this.chosenTargetCarM4) document.getElementById("targetImage").src = this.chosenTargetCarM4;

    // Initialize the truck in the center lane if it is a jerk, hide the target car (m2)
    if (this.followTruckSignal === 1 || (this.followTruckSignal === 0 && this.truckMoves === 0)) {
      if (this.truckType === "jerk") {
        this.initializeTruck(this.targetLane);
      } else {
        this.initializeTruck("center");
      }
      hideElement(this.backTargetCar);
    }

    //mission3, means driver is capturing package2, so show package2
    if (this.escapism && chosenLane === 1) {
      this.initializePackage2M3();
    }
    //mission3, show front bus if applicable
    if (this.occurrenceFrontbus) {
      this.initializeFrontBusM3();
    }

    // Display the package and target car moving animations
    this.playPackageAnimation = true;
    this.moveTargetCarCheck = true;

    // Add the package to the lane and animate it
    this.addPackageToLane();
    this.playPackageAnimation = true;
    // animate target car as well
    this.moveTargetCarCheck = true;

    if (this.trialConfig.agentFile.length === 2) {
      this.agentImage.src = `assets/images/headquarter/${this.trialConfig.agentFile[1]}`;
      showElement(this.agentImage);
    } else if (
      this.trialConfig.agentFile === "footer_LaneLabels.svg" ||
      this.trialConfig.agentFile === "footer_LaneLabels_M2.svg" ||
      this.trialConfig.agentFile === "footer_LaneLabels_M3.svg"
    ) {
      showElement(this.agentImage);
    }

    this.phase5KeyboardListerID = this.jsPsych.pluginAPI.getKeyboardResponse({
      callback_function: this.driveUpKeypress_phase5.bind(this),
      allow_held_key: false,
      persist: true,
    });
  }

  /** Executes phase 6 of the game, exclusive to trial type MESSAGE */
  startPhase6() {
    this.startPhase(6);

    // Hide the target car and show the agent image
    hideElement(this.targetCar);
    showElement(this.agentImage);
  }

  /** Executes headquarter survey */
  startHeadquarterSurveyPhase() {
    this.startPhase(6);

    // Hide the target car and show the agent image
    hideElement(this.targetCar);
    this.agentImage.src = "assets/images/headquarter/headquarterSurveyBanner.svg";
    showElement(this.agentImage);
  }

  /** Executes phase 7 of the game */
  startPhase7() {
    this.startPhase(7);

    // Hide the target car and revert the game
    hideElement(this.targetCar);
    this.revertGameBoard();
  }

  /** ------------- MISSION 2 Exclusive Phases ------------- */

  /* Executes phase 1.1 of mission 2 */
  startPhase1_1() {
    this.startPhase(1.1);

    // Initialize the truck and move the target car behind it
    this.initializeTruck("center");
    this.targetCarCoordinates.top = `${this.truckMoveDistance - 5}px`;

    // Stop moving the car and truck, display the signal
    this.moveTargetCarCheck = false;
    this.moveTruckCheck = false;
    this.displayTruckSignal();

    if (this.trialConfig.agentFile === "footer_LaneLabels_M2.svg") showElement(this.agentImage);
  }

  /* Executes phase 1.2 of mission 2 */
  startPhase1_2() {
    this.startPhase(1.2);

    // Initialize the truck and display the signal
    this.initializeTruck("center");
    this.moveTargetCarCheck = false;
    this.displayTruckSignal();

    if (this.truckMoves === 1) {
      // Blur the target car and set the truck to move to the 5th lane
      this.targetImage.src = TARGET_BLURRED_FILE;
      this.moveTruckCheck = true;
      this.moveTruckLane = LANE_COORDINATES[5];
    } else {
      // Hide the target car
      hideElement(this.backTargetCar);
      // NOTE: Phase continues indefinitely until a lane is chosen
    }
  }

  /** ------------- MISSION 3 Exclusive Phases ------------- */

  startPhase3_M3() {
    this.startPhase(3);

    this.phase3KeyboardListerID = this.jsPsych.pluginAPI.getKeyboardResponse({
      callback_function: this.chooseLaneKeypress.bind(this),
      valid_responses: Object.keys(KEY_CODES).filter((key) => key !== ";"),
      allow_held_key: false,
    });

    // Display the package and the license
    this.showPackageOnTargetCar();
    this.displayLicense(true);
    showElement(this.agentImage);

    if (this.escapism) {
      setTimeout(() => {
        this.initializePackage2M3();
        this.movePackage2CheckM3 = true;
      }, this.timeOnset_escapePackage);
    }
    if (this.occurrenceFrontbus) {
      setTimeout(() => {
        this.initializeFrontBusM3();
        this.moveFrontbusCheckM3 = true;
      }, this.timeOnsetFrontbus_fixed);
    }

    // Begin the bomb countdown animation for package release mechanism 2
    if (this.releaseMechanism === 2) this.countdownBombs();
  }

  /** ------------- MISSION 4 Exclusive Phases ------------- */

  startPhase0_1M4() {
    this.startPhase(0.1);
    hideElement(this.targetCar);
    this.agentImage.src = `assets/images/headquarter/${this.trialConfig.agentFile[0]}`;
    showElement(this.agentImage);
    this.initializeTargetCarsM4();
    this.displayMission4ContextCue(true);
  }

  startPhase0_2M4(chosenLane) {
    this.startPhase(0.2);
    hideElement(this.targetCar);
    this.initializeTargetCarsM4();
    this.driverCarMoveLane = chosenLane;

    this.displayMission4ContextCueFeedback(this.trialConfig.correctTargetLane === chosenLane);
  }

  startPhase0_3M4(chosenLane) {
    this.startPhase(0.3);
    hideElement(this.targetCar);
    this.initializeTargetCarsM4();

    this.phase0KeyboardListerID = this.jsPsych.pluginAPI.getKeyboardResponse({
      callback_function: this.driveUpKeypress.bind(this),
      allow_held_key: false,
      persist: true,
    });

    this.moveTargetCarCheck = true;
    this.displayMission4ContextCueFeedback(this.trialConfig.correctTargetLane === chosenLane);
  }

  startPhase2M4() {
    this.startPhase(2);

    this.showPackageOnTargetCar();
    this.backTargetCar.style.opacity = "100%";
    this.backTargetCar.style.filter = "blur(0)";
    document.getElementById("targetImage").src = this.chosenTargetCarM4;
    this.driverCarSpeed = this.defaultDriverCarSpeed;
    this.moveTruckM4Check = true;
    //adjust truckSpeed variable to use desired truck1Speed
    this.truckSpeed = this.truck1Speed;
    this.initializeTarget(this.truck1Lane, this.trialConfig.truck1Image);
  }

  startPhase3M4() {
    this.startPhase(3);
    document.getElementById("targetImage").src = this.chosenTargetCarM4;

    // Display the package and the license
    this.showPackageOnTargetCar();
    this.displayLicense(true);
    setTimeout(() => {
      this.moveTruckM4Check = true;
      //adjust truckSpeed variable to use desired truck2Speed
      this.truckSpeed = this.truck2Speed;
      this.initializeTarget(this.truck2Lane, this.trialConfig.truck2Image);

      this.phase3KeyboardListerID = this.jsPsych.pluginAPI.getKeyboardResponse({
        callback_function: this.chooseLaneKeypress.bind(this),
        valid_responses: Object.keys(KEY_CODES).filter((key) => key !== "f" && key !== "k"),
        allow_held_key: false,
      });
    }, this.truck2Onset);

    //show footer lane banner
    this.agentImage.src = `assets/images/headquarter/${this.trialConfig.agentFile[1]}`;
    showElement(this.agentImage);

    // Begin the bomb countdown animation for package release mechanism 2
    if (this.releaseMechanism === 2) this.countdownBombs();
  }

  /** ------------- MISSION 5 Exclusive Phases ------------- */

  //show two target cars, truck, driver
  //user will decide whether to cooperate or not
  startPhase0_1M5() {
    this.startPhase(0.1);
    this.agentImage.src = `assets/images/headquarter/${this.trialConfig.agentFile[0]}`;
    showElement(this.agentImage);
    this.targetCar.style.display = "none";
    this.initializeTargetCarsM5(false);
    this.initializeTruckM5(this.truckLane, 0);
  }

  //conditional phase cooperate == true
  //move truck from left lane to center lane
  startPhase0_2M5() {
    this.startPhase(0.2);
    this.targetCar.style.display = "none";
    this.initializeTargetCarsM5(false);
    this.initializeTruckM5(this.truckLane, 0);
    this.moveTruckCheck = true;
    this.moveTruckLane = "center";
  }

  //for both phases where cooperate == true or cooperate == false
  //values will be shown on left/right of each target car
  //user chooses lane for driver
  startPhase0_3M5() {
    this.startPhase(0.3);
    this.agentImage.src = `assets/images/headquarter/${this.trialConfig.agentFile[2]}`;
    showElement(this.agentImage);
    this.targetCar.style.display = "none";
    if (this.cooperating === 1) {
      this.initializeTruckM5("center", 95);
    }
    this.initializeTargetCarsM5(true);
    this.initializeSpecialPackagesM5(true);
  }

  //conditional phase cooperate == true
  //propose lane partner shows up in license window
  //now user chooses a lane for its partner.
  startPhase0_4M5() {
    this.startPhase(0.4);
    this.agentImage.src = `assets/images/headquarter/${this.trialConfig.agentFile[1]}`;
    showElement(this.agentImage);
    this.targetCar.style.display = "none";
    if (this.cooperating === 1) {
      this.initializeTruckM5("center", 95);
    }
    this.initializeTargetCarsM5(true);
    this.initializeSpecialPackagesM5(true);
  }

  //part two of phase 4
  //display the drive proposed lane for confirmation
  startPhase0_4BM5(proposedLane) {
    this.startPhase(0.4);
    this.targetCar.style.display = "none";
    if (this.cooperating === 1) {
      this.initializeTruckM5("center", 95);
    }
    this.displayDriverProposeLane(proposedLane);
    this.initializeTargetCarsM5(true);
    this.initializeSpecialPackagesM5(true);
  }

  //for both phases where cooperate == true or cooperate == false
  //for cooperate == true
  //now that two lanes are chosen, 1 for driver and 1 for partner
  //move driver car to chosen lane
  //move truck to partner lane

  //for cooperate == false
  //just move driver to chosen lane
  startPhase0_5M5(chosenLane) {
    this.startPhase(0.5);
    this.targetCar.style.display = "none";
    this.initializeTargetCarsM5(true);
    if (this.cooperating === 1) {
      this.initializeTruckM5("center", 95, "");
    }
    this.initializeSpecialPackagesM5(true);
    //if 2, means move driver and truck
    if (chosenLane.length === 2) {
      //chosenLane[0] = user lane
      //chosenLane[1] = partner lane
      this.driverCarMoveLane = chosenLane[0];

      this.moveTruckCheck = true;
      if (this.truckFollowProposal === 1) {
        this.moveTruckLane = chosenLane[1];
      } else {
        //if not follow truck proposal, then set to paired lane.
        //if 1 or 3, then 5 or 7
        //if 5 or 7, then 1 or 3
        let chosenLaneValue = chosenLane[1];

        if (chosenLaneValue < 4) {
          //TODO: check this is working
          this.moveTruckLane = chosenLaneValue + 4;
        } else {
          this.moveTruckLane = chosenLaneValue - 4;
        }
      }
    } else {
      //only moving driver
      this.driverCarMoveLane = chosenLane;
    }
  }

  startPhase0_6M5(chosenLane) {
    this.startPhase(0.6);
    this.targetCar.style.display = "none";
    this.initializeTargetCarsM5(true);
    if (this.cooperating === 1) {
      this.initializeTruckM5(this.moveTruckLane, 95, "");
    }
    this.initializeSpecialPackagesM5(true);
    this.driverPackageLane = chosenLane[0];
    if (chosenLane.length === 2) {
      this.truckPackageLane = chosenLane[1];
    }
    this.playPackageAnimation = true;

    this.phase5KeyboardListerID = this.jsPsych.pluginAPI.getKeyboardResponse({
      callback_function: this.driveUpKeypress_phase5.bind(this),
      allow_held_key: false,
      persist: true,
    });
  }

  // ---------- INITIALIZATION FUNCTIONS --------------------

  /**
   * Attaches the game elements to the class
   * NOTE: We cannot call this in the constructor because the HTML elements are not present
   */
  initializeGameComponents() {
    this.parentWrapper = document.getElementById("parentWrapper");
    this.header = document.getElementById("header");
    this.headerMission = document.getElementById("headerMission");
    this.headerProgress = document.getElementById("headerProgress");
    this.headerReward = document.getElementById("headerReward");
    this.missionRewardText = document.getElementById("missionRewardText");
    this.headerSign = document.getElementById("headerSign");
    this.stimulusImage = document.getElementById("stimulusImage");
    this.headerBomb = document.getElementById("headerBomb");
    this.headerTruck = document.getElementById("headerTruck");
    this.headerLives = document.getElementById("headerLives");
    this.missionLivesText = document.getElementById("missionLivesText");
    this.gameWrapper = document.getElementById("gameWrapper");
    this.gameBoard = document.getElementById("gameBoard");
    this.driverCar = document.getElementById("driverCar");
    this.backDriverCar = document.getElementById("backDriverCar");
    this.driverImage = document.getElementById("driverImage");
    this.driverScore = document.getElementById("driverScore");
    this.targetCar = document.getElementById("targetCar");
    this.backTargetCar = document.getElementById("backTargetCar");
    this.targetImage = document.getElementById("targetImage");
    this.highwayPointer = document.getElementById("highwayPointer");
    this.allHighwayDividers = document.getElementById("allHighwayDividers");
    this.footer = document.getElementById("footer");
    this.footerFuel = document.getElementById("footerFuel");
    this.gasNeedle = document.getElementById("gasNeedle");
    this.footerAgent = document.getElementById("footerAgent");
    this.agentImage = document.getElementById("agentImage");
    this.footerSpeed = document.getElementById("footerSpeed");
    this.speedNeedle = document.getElementById("speedNeedle");
  }

  initializeTargetCarsM4() {
    //initialize lane3 and lane5 target cars

    const leftTargetCarBody = document.createElement("div");
    leftTargetCarBody.id = "targetCarLane3Body";
    leftTargetCarBody.style.width = "40px";
    leftTargetCarBody.style.height = "5px";
    leftTargetCarBody.style.position = "absolute";
    leftTargetCarBody.style.top = `0px`;
    leftTargetCarBody.style.left = `140px`;
    leftTargetCarBody.style.transformStyle = "preserve-3d";

    const leftTargetCarBack = document.createElement("div");
    leftTargetCarBack.id = "leftTargetCarBack";
    leftTargetCarBack.style.width = "40px";
    leftTargetCarBack.style.height = "50px";
    leftTargetCarBack.style.position = "absolute";
    leftTargetCarBack.style.top = "5px";
    leftTargetCarBack.style.transform = "rotateX(90deg)";
    leftTargetCarBack.style.transformOrigin = "top";

    const leftTargetCarImage = document.createElement("img");
    leftTargetCarImage.id = "leftTargetCarImage";
    leftTargetCarImage.src = `assets/images/targetCars/${this.trialConfig.targetLeft}`;
    leftTargetCarImage.alt = "leftTargetCarImage image";
    leftTargetCarImage.style.width = "40px";
    leftTargetCarImage.style.height = "50px";
    leftTargetCarImage.style.transform = "rotateX(180deg)";

    const rightTargetCarBody = document.createElement("div");
    rightTargetCarBody.id = "targetCarLane5Body";
    rightTargetCarBody.style.width = "40px";
    rightTargetCarBody.style.height = "5px";
    rightTargetCarBody.style.position = "absolute";
    rightTargetCarBody.style.top = `0px`;
    rightTargetCarBody.style.left = `270px`;
    rightTargetCarBody.style.transformStyle = "preserve-3d";

    const rightTargetCarBack = document.createElement("div");
    rightTargetCarBack.id = "rightTargetCarBack";
    rightTargetCarBack.style.width = "40px";
    rightTargetCarBack.style.height = "50px";
    rightTargetCarBack.style.position = "absolute";
    rightTargetCarBack.style.top = "5px";
    rightTargetCarBack.style.transform = "rotateX(90deg)";
    rightTargetCarBack.style.transformOrigin = "top";

    const rightTargetCarImage = document.createElement("img");
    rightTargetCarImage.id = "rightTargetCarImage";
    rightTargetCarImage.src = `assets/images/targetCars/${this.trialConfig.targetRight}`;
    rightTargetCarImage.alt = "rightTargetCarImage image";
    rightTargetCarImage.style.width = "40px";
    rightTargetCarImage.style.height = "50px";
    rightTargetCarImage.style.transform = "rotateX(180deg)";

    leftTargetCarBack.appendChild(leftTargetCarImage);
    leftTargetCarBody.appendChild(leftTargetCarBack);

    rightTargetCarBack.appendChild(rightTargetCarImage);
    rightTargetCarBody.appendChild(rightTargetCarBack);

    this.gameBoard.appendChild(leftTargetCarBody);
    this.gameBoard.appendChild(rightTargetCarBody);
  }

  initializeTarget(lane, vehicle) {
    //initialLaneTruck
    const leftCoordinate = LANE_COORDINATES[lane];
    let bottomCoordinate;

    if (lane === 3 || lane === 1 || this.moveTruckM4Check) {
      bottomCoordinate = 0;
    } else {
      bottomCoordinate = 95;
    }

    const truckBody = document.createElement("div");
    truckBody.id = "truckBody";
    truckBody.style.width = "40px";
    truckBody.style.height = "5px";
    truckBody.style.position = "absolute";
    truckBody.style.bottom = `${bottomCoordinate}px`;
    truckBody.style.left = `${leftCoordinate}px`;
    truckBody.style.transformStyle = "preserve-3d";

    const truckBack = document.createElement("div");
    truckBack.id = "truckBack";
    truckBack.style.width = "40px";
    truckBack.style.height = "50px";
    truckBack.style.position = "absolute";
    truckBack.style.top = "5px";
    truckBack.style.transform = "rotateX(90deg)";
    truckBack.style.transformOrigin = "top";

    const truckImage = document.createElement("img");
    truckImage.id = "targetImage";
    truckImage.src = `assets/images/targetCars/${vehicle}`;
    truckImage.alt = "truck image";
    truckImage.style.width = "40px";
    truckImage.style.height = "50px";
    truckImage.style.transform = "rotateX(180deg)";

    truckBack.appendChild(truckImage);
    truckBody.appendChild(truckBack);
    this.gameBoard.appendChild(truckBody);
  }

  /**
   * Displays the score and other elements in the header and footer
   * NOTE: We cannot call this in the constructor because the HTML elements are not present
   */
  initializeHUD() {
    // Set the current number reward
    this.missionRewardText.textContent = this.score.missionReward;

    // Set the current number of lives
    this.missionLivesText.textContent = this.score.missionLives;

    // Set the gas angle (TEMP: always full)
    this.gasNeedle.style.transform = "rotate(90deg)";

    // Set speedometer angle (TEMP: always min speed)
    // TODO: Need to hook this up based on the driver speed(s)
    this.speedNeedle.style.transform = "rotate(-90deg)";
  }

  /** Initialize the truck game component */
  // TODO: These divs can be added to the game HTML
  // TODO: The coordinates should be kept track of in the coordinate class
  initializeTruck(lane) {
    // Initialize the starting coordinates of the  truck
    let leftCoordinate;
    let bottomCoordinate;
    if (lane === "left") {
      leftCoordinate = LANE_COORDINATES[3];
      bottomCoordinate = 0;
    } else if (lane === "center") {
      leftCoordinate = LANE_COORDINATES["center"];
      if (this.gamePhase === 5) {
        bottomCoordinate = 0;
      } else {
        bottomCoordinate = BOARD_HEIGHT - this.truckMoveDistance - 5;
      }
    } else {
      leftCoordinate = LANE_COORDINATES[lane];
      bottomCoordinate = BOARD_HEIGHT - this.truckMoveDistance - 5;
    }

    const truckBody = document.createElement("div");
    truckBody.id = "truckBody";
    truckBody.style.width = "40px";
    truckBody.style.height = "5px";
    truckBody.style.position = "absolute";
    truckBody.style.bottom = `${bottomCoordinate}px`;
    truckBody.style.left = `${leftCoordinate}px`;
    truckBody.style.transformStyle = "preserve-3d";

    const truckBack = document.createElement("div");
    truckBack.id = "truckBack";
    truckBack.style.width = "40px";
    truckBack.style.height = "50px";
    truckBack.style.position = "absolute";
    truckBack.style.top = "5px";
    truckBack.style.transform = "rotateX(90deg)";
    truckBack.style.transformOrigin = "top";

    const truckImage = document.createElement("img");
    // TODO: Different ID here? Target image is for the target car?
    truckImage.id = "targetImage";
    truckImage.src = `assets/images/truck/${this.trialConfig.truckImage}`;
    truckImage.alt = "targetCar image";
    truckImage.style.width = "40px";
    truckImage.style.height = "50px";
    truckImage.style.transform = "rotateX(180deg)";

    const truckScore = document.createElement("div");
    truckScore.id = "truckScore";
    truckScore.className = "invisible";

    truckBack.appendChild(truckImage);
    truckBack.appendChild(truckScore);
    truckBody.appendChild(truckBack);
    this.gameBoard.appendChild(truckBody);
  }

  initializePackage2M3() {
    //initialize package2 in lane 1

    const packageBody = document.createElement("div");
    packageBody.id = "package2Body";
    packageBody.style.width = "30px";
    packageBody.style.height = "5px";
    packageBody.style.position = "absolute";
    if (this.gamePhase === 3) {
      packageBody.style.top = `0px`;
    } else {
      //for phase4 and after
      packageBody.style.top = `${BOARD_HEIGHT - 5}px`;
    }
    packageBody.style.left = `${LANE_COORDINATES[1] + 5}px`;
    packageBody.style.transformStyle = "preserve-3d";

    const packageBack = document.createElement("div");
    packageBack.id = "package2Back";
    packageBack.style.width = "30px";
    packageBack.style.height = "30px";
    packageBack.style.position = "absolute";
    packageBack.style.top = "5px";
    packageBack.style.transform = "rotateX(90deg)";
    packageBack.style.transformOrigin = "top";

    const packageImage = document.createElement("img");
    packageImage.id = "packageImage";
    packageImage.src = `assets/images/packages/${this.trialConfig.escapePackage}`;
    packageImage.alt = `package2 image`;
    packageImage.style.width = "30px";
    packageImage.style.height = "30px";
    packageImage.style.transform = "rotateX(180deg)";
    packageImage.style.verticalAlign = "bottom";

    packageBack.appendChild(packageImage);
    packageBody.appendChild(packageBack);
    this.gameBoard.appendChild(packageBody);
  }

  initializeFrontBusM3() {
    //initialize frontbus in lane 7
    const frontbusBody = document.createElement("div");
    frontbusBody.id = "frontbusBody";
    frontbusBody.style.width = "40px";
    frontbusBody.style.height = "5px";
    frontbusBody.style.position = "absolute";
    if (this.gamePhase === 3) {
      frontbusBody.style.top = `0px`;
    } else {
      //for phase4 and after
      frontbusBody.style.top = `${BOARD_HEIGHT - 5}px`;
    }
    frontbusBody.style.left = `${LANE_COORDINATES[7]}px`;
    frontbusBody.style.transformStyle = "preserve-3d";

    const frontbusBack = document.createElement("div");
    frontbusBack.id = "frontbusBack";
    frontbusBack.style.width = "40px";
    frontbusBack.style.height = "50px";
    frontbusBack.style.position = "absolute";
    frontbusBack.style.top = "5px";
    frontbusBack.style.transform = "rotateX(90deg)";
    frontbusBack.style.transformOrigin = "top";

    const frontbusImage = document.createElement("img");
    frontbusImage.id = "frontbusImage";
    frontbusImage.src = `assets/images/frontbus/${this.trialConfig.frontbusImage}`;
    frontbusImage.alt = "frontbus image";
    frontbusImage.style.width = "40px";
    frontbusImage.style.height = "50px";
    frontbusImage.style.transform = "rotateX(180deg)";

    frontbusBack.appendChild(frontbusImage);
    frontbusBody.appendChild(frontbusBack);
    this.gameBoard.appendChild(frontbusBody);
  }

  /**
   * Calculates the distance between the driver and target cars
   * @returns {number} The distance (in pixels) between the cars
   */
  calculateDriverDistanceToTarget() {
    const targetCarCurrentTop = parseInt(window.getComputedStyle(this.targetCar).top);
    const driverCarCurrentTop = parseInt(window.getComputedStyle(this.driverCar).top);
    const targetCarBottom =
      targetCarCurrentTop + parseInt(window.getComputedStyle(this.targetCar).height);
    return driverCarCurrentTop - targetCarBottom;
  }

  /**
   * Calculates the distance between the package and driver car
   * @returns {number} The distance (in pixels) between the elements
   */
  calculatePackageDistanceFromTarget() {
    const packageCurrentTop = parseInt(
      window.getComputedStyle(document.getElementById("packageBody")).top
    );
    const driverCarCurrentTop = parseInt(window.getComputedStyle(this.driverCar).top);
    const packageBottom =
      packageCurrentTop +
      parseInt(window.getComputedStyle(document.getElementById("packageBody")).height);

    return driverCarCurrentTop - packageBottom;
  }

  /**
   * Speeds the driver car up by this.speedIncrement
   * Works if:
   * 1) The truck is not present
   * 2) The truck is present and the user has the ability to avoid it
   */
  speedUp() {
    // TODO: Pull this boolean into its own check, used elsewhere
    if (this.occurrenceTruck === 0 || (this.occurrenceTruck === 1 && this.avoidTruck === 1)) {
      this.driverCarSpeed = Math.min(
        this.driverCarSpeed + this.speedIncrement,
        this.maxDriverCarSpeed
      );
      if (this.avoidTruck === 1) {
        this.truckSpeed -= 2;
        this.preventTruck = 1;
      }
    }
  }

  /**
   * Speeds up packageSpeed by 1
   */
  speedUp_Phase5() {
    this.packageSpeed += 1;
  }

  /**
   * Slows the driver car down by this.speedIncrement
   * Works if:
   * 1) The truck is not present
   * 2) The truck is present and the user has the ability to avoid it
   */
  slowDown() {
    if (this.occurrenceTruck === 0 || (this.occurrenceTruck === 1 && this.avoidTruck === 1)) {
      this.driverCarSpeed = Math.max(
        this.driverCarSpeed - this.speedIncrement,
        this.minDriverCarSpeed
      );
    }
  }

  /**
   * Slows the package speed by 1, minimum of packageSpeed = 1
   */
  slowDown_Phase5() {
    this.packageSpeed = Math.max(1, this.packageSpeed - 1);
  }

  /**
   * Checks to see if the target car is within the target distance
   * @returns {boolean} If the target car is within the target distance
   */
  checkProximityZone() {
    const targetCarCurrentTop = parseInt(window.getComputedStyle(this.targetCar).top);
    const driverCarCurrentTop = parseInt(window.getComputedStyle(this.driverCar).top);
    const targetCarBottom =
      targetCarCurrentTop + parseInt(window.getComputedStyle(this.targetCar).height);
    return driverCarCurrentTop - this.targetDistance <= targetCarBottom;
  }

  checkProximityZoneM4(keypress) {
    const userChosenTargetCarBottom = parseInt(
      window.getComputedStyle(document.getElementById(`targetCarLane${this.phase0ChosenLane}Body`))
        .top
    );
    const driverCarCurrentTop = parseInt(window.getComputedStyle(this.driverCar).top);

    // if the space key was pressed while the targetCar is within the proximity zone will trigger next phase of the game
    if (driverCarCurrentTop - this.targetDistance <= userChosenTargetCarBottom) {
      //set chosen targetCar to preset distance in front of driver
      document.getElementById(`targetCarLane${this.phase0ChosenLane}Body`).style.top =
        `${parseInt(window.getComputedStyle(this.driverCar).top) - this.targetAppearance}px`;

      //Need to set targetCar now to chosen target car for rest of the mission
      this.chosenTargetCarM4 = `assets/images/targetCars/${this.phase0ChosenLane === 3 ? this.trialConfig.targetLeft : this.trialConfig.targetRight}`;

      //hide other targetCar, the one not chosen
      document.getElementById(
        `targetCarLane${this.phase0ChosenLane === 3 ? 5 : 3}Body`
      ).style.display = "none";

      //targetCar and driverCar speeds need to match
      this.driverCarSpeed = this.defaultDriverCarSpeed;
      //disable targetCar moving
      this.moveTargetCarCheck = false;

      //need to set original targetCar to the chosen targetCar coordinates.
      //all targetCar animations will now use the original targetCar
      this.targetCarCoordinates.top = document.getElementById(
        `targetCarLane${this.phase0ChosenLane}Body`
      ).style.top;
      this.targetCarCoordinates.left = document.getElementById(
        `targetCarLane${this.phase0ChosenLane}Body`
      ).style.left;

      //show package on targetCar
      this.driverDistanceToTarget = this.calculateDriverDistanceToTarget();

      this.jsPsych.pluginAPI.cancelKeyboardResponse(this.phase0KeyboardListerID);
      this.jsPsych.finishTrial({
        collision: false,
        driveupKeyboardPresses: this.phase0KeyboardPresses,
        driverSpeed: this.driverCarSpeed,
        targetSpeed: this.targetCarSpeed,
        distanceToTarget: this.driverDistanceToTarget,
        response: keypress.key,
        rt: keypress.rt,
      });
    }
  }

  /** Matches the driver's and target car's speed at defaultDriverCarSpeed */
  matchDriverAndTargetSpeed() {
    // Set fixed distance between the driver and target car at the bottom of the screen
    this.targetCarCoordinates.top = `${parseInt(window.getComputedStyle(this.driverCar).top) - this.targetAppearance}px`;
    this.updateCoordinates();

    // Disable car moving and set speed to default
    this.driverCarSpeed = this.defaultDriverCarSpeed;
    this.moveTargetCarCheck = false;
  }

  // ---------- KEYBOARD EVENTS --------------------

  /**
   * Callback function for the keyboard event listener used in phase 1 of the experiment
   * Handles the driver car "driving up" to the target car
   * Custom information is appended to the keypress and we keep track of it in phase1KeyboardPresses
   * @param {Object} keypress The keypress info from jsPsych
   */
  driveUpKeypress(keypress) {
    // NOTE: keypress includes "rt" from when the listener was created
    // This is from when the experiment began
    keypress.timestamp = performance.now();

    if (this.gamePhase === 0.3) {
      if (keypress.key === " ") {
        keypress.driverNewSpeed = this.driverCarSpeed;
        keypress.distanceToTarget = this.driverDistanceToTarget;
        this.phase0KeyboardPresses.push(keypress);
        this.checkProximityZoneM4(keypress);
      }
      return;
    }
    let inTargetZone = false;
    switch (keypress.key) {
      case "z":
        keypress.validKey = true;
        this.slowDown();
        break;
      case "/":
        keypress.validKey = true;
        this.speedUp();
        break;
      case " ":
        keypress.validKey = true;
        inTargetZone = this.checkProximityZone();
        break;
      default:
        keypress.validKey = false;
        break;
    }

    // Add driver speed and distance to the keypress object and append it to the keypress array
    keypress.driverNewSpeed = this.driverCarSpeed;
    keypress.distanceToTarget = this.driverDistanceToTarget;
    this.phase1KeyboardPresses.push(keypress);

    // Successful driveup!
    if (inTargetZone) {
      this.matchDriverAndTargetSpeed();
      this.jsPsych.pluginAPI.cancelKeyboardResponse(this.phase1KeyboardListerID);
      this.jsPsych.finishTrial({
        crashed: false,
        driveupKeyboardPresses: this.phase1KeyboardPresses,
        driverSpeed: this.driverCarSpeed,
        targetSpeed: this.targetCarSpeed,
        distanceToTarget: this.driverDistanceToTarget,
        response: keypress.key,
        rt: keypress.rt,
      });
    }
  }

  driveUpKeypress_phase5(keypress) {
    // NOTE: keypress includes "rt" from when the listener was created
    // This is from when the experiment began
    keypress.timestamp = performance.now();

    // let inTargetZone = false;
    switch (keypress.key) {
      case "z":
        keypress.validKey = true;
        this.slowDown_Phase5();
        break;
      case "/":
        keypress.validKey = true;
        this.speedUp_Phase5();
        break;
      default:
        keypress.validKey = false;
        break;
    }

    // Add driver speed and distance to the keypress object and append it to the keypress array
    keypress.packageNewSpeed = this.packageSpeed;
    this.phase5KeyboardPresses.push(keypress);
  }

  /**
   * Callback function for the keyboard event listener used in phase 3 of the experiment
   * Handles the driver car selecting which lane to move to
   * NOTE: The jsPsych listener only looks for keys of KEY_CODES
   * @param {Object} keypress The keypress info from jsPsych
   * @param {Object} keypress.key The key that the user pressed
   * @param {Object} keypress.rt The reaction time of the keypress
   */
  chooseLaneKeypress(keypress) {
    // Hide the bomb and clear the timeout used for the countdown
    hideElement(this.headerBomb);
    clearInterval(this.phase3IntervalID);

    //For mission4, we stop the moveTruck animation at time of user keypress
    if (this.moveTruckM4Check) this.moveTruckM4Check = false;

    //mission3 exclusive, stop the following animations
    if (this.escapism) this.movePackage2CheckM3 = false;
    if (this.occurrenceFrontbus) this.moveFrontbusCheckM3 = false;

    // Finish the trial!
    this.jsPsych.finishTrial({ response: keypress.key, rt: keypress.rt });
  }

  initializeTargetCarsM5(showScore) {
    //initialize lane3 and lane5 target cars

    const leftTargetCarBody = document.createElement("div");
    leftTargetCarBody.id = "targetCarLane2Body";
    leftTargetCarBody.style.width = "40px";
    leftTargetCarBody.style.height = "5px";
    leftTargetCarBody.style.position = "absolute";
    leftTargetCarBody.style.top = `0px`;
    leftTargetCarBody.style.left = `${LANE_COORDINATES[this.carLeftLane]}px`;
    leftTargetCarBody.style.transformStyle = "preserve-3d";

    const leftTargetCarBack = document.createElement("div");
    leftTargetCarBack.id = "leftTargetCarBack";
    leftTargetCarBack.style.width = "40px";
    leftTargetCarBack.style.height = "50px";
    leftTargetCarBack.style.position = "absolute";
    leftTargetCarBack.style.top = "5px";
    leftTargetCarBack.style.transform = "rotateX(90deg)";
    leftTargetCarBack.style.transformOrigin = "top";

    const leftTargetCarImage = document.createElement("img");
    leftTargetCarImage.id = "leftTargetCarImage";
    leftTargetCarImage.src = `assets/images/gangCars/${this.trialConfig.carLeft}`;
    leftTargetCarImage.alt = "leftTargetCarImage image";
    leftTargetCarImage.style.width = "40px";
    leftTargetCarImage.style.height = "50px";
    leftTargetCarImage.style.transform = "rotateX(180deg)";

    const rightTargetCarBody = document.createElement("div");
    rightTargetCarBody.id = "targetCarLane6Body";
    rightTargetCarBody.style.width = "40px";
    rightTargetCarBody.style.height = "5px";
    rightTargetCarBody.style.position = "absolute";
    rightTargetCarBody.style.top = `0px`;
    rightTargetCarBody.style.left = `${LANE_COORDINATES[this.carRightLane]}px`;
    rightTargetCarBody.style.transformStyle = "preserve-3d";

    const rightTargetCarBack = document.createElement("div");
    rightTargetCarBack.id = "rightTargetCarBack";
    rightTargetCarBack.style.width = "40px";
    rightTargetCarBack.style.height = "50px";
    rightTargetCarBack.style.position = "absolute";
    rightTargetCarBack.style.top = "5px";
    rightTargetCarBack.style.transform = "rotateX(90deg)";
    rightTargetCarBack.style.transformOrigin = "top";

    const rightTargetCarImage = document.createElement("img");
    rightTargetCarImage.id = "rightTargetCarImage";
    rightTargetCarImage.src = `assets/images/gangCars/${this.trialConfig.carRight}`;
    rightTargetCarImage.alt = "rightTargetCarImage image";
    rightTargetCarImage.style.width = "40px";
    rightTargetCarImage.style.height = "50px";
    rightTargetCarImage.style.transform = "rotateX(180deg)";

    leftTargetCarBack.appendChild(leftTargetCarImage);
    leftTargetCarBody.appendChild(leftTargetCarBack);

    rightTargetCarBack.appendChild(rightTargetCarImage);
    rightTargetCarBody.appendChild(rightTargetCarBack);

    if (showScore) {
      const leftTargetCarScore = document.createElement("div");
      leftTargetCarScore.id = "targetCarLane2BodyScore";
      leftTargetCarScore.className = "driverScore";
      leftTargetCarScore.innerText = this.trialConfig.carLeftNumber;
      leftTargetCarScore.style.fontSize = "32px";
      leftTargetCarScore.style.color = "#EB6123";
      leftTargetCarBack.appendChild(leftTargetCarScore);

      const rightTargetCarScore = document.createElement("div");
      rightTargetCarScore.id = "targetCarLane6BodyScore";
      rightTargetCarScore.className = "driverScore";
      rightTargetCarScore.innerText = this.trialConfig.carRightNumber;
      rightTargetCarScore.style.fontSize = "32px";
      rightTargetCarScore.style.color = "#EB6123";
      rightTargetCarBack.appendChild(rightTargetCarScore);
    }

    this.gameBoard.appendChild(leftTargetCarBody);
    this.gameBoard.appendChild(rightTargetCarBody);
  }

  initializeTruckM5(lane, bCoordinate) {
    //initialLaneTruck
    const leftCoordinate = LANE_COORDINATES[lane];

    const bottomCoordinate = bCoordinate;

    const truckBody = document.createElement("div");
    truckBody.id = "truckBody";
    truckBody.style.width = "40px";
    truckBody.style.height = "5px";
    truckBody.style.position = "absolute";
    truckBody.style.bottom = `${bottomCoordinate}px`;
    truckBody.style.left = `${leftCoordinate}px`;
    truckBody.style.transformStyle = "preserve-3d";

    const truckBack = document.createElement("div");
    truckBack.id = "truckBack";
    truckBack.style.width = "40px";
    truckBack.style.height = "50px";
    truckBack.style.position = "absolute";
    truckBack.style.top = "5px";
    truckBack.style.transform = "rotateX(90deg)";
    truckBack.style.transformOrigin = "top";

    const truckImage = document.createElement("img");
    truckImage.id = "truckImage";
    truckImage.src = `assets/images/truck/${this.trialConfig.truckImage}`;
    truckImage.alt = "truck image";
    truckImage.style.width = "40px";
    truckImage.style.height = "50px";
    truckImage.style.transform = "rotateX(180deg)";

    truckBack.appendChild(truckImage);
    truckBody.appendChild(truckBack);
    this.gameBoard.appendChild(truckBody);
  }

  initializeSpecialPackagesM5(showScore) {
    //each package has the package as well as a number above it
    //A total of 4 packages in Lanes 1, 3, 5, 7

    const lanes = [1, 3, 5, 7];

    lanes.forEach((lane, index) => {
      const coordinates = LANE_COORDINATES[lane] + 5;

      // Create package element
      const packageBody = this.createPackageBodyM5(coordinates);
      packageBody.id = `packageBody${lanes[index]}`;
      const packageBack = this.createPackageBackM5(lanes[index], showScore);

      packageBody.appendChild(packageBack);
      this.gameBoard.appendChild(packageBody);
    });
  }

  createPackageBodyM5(coordinates) {
    const packageBody = document.createElement("div");
    // packageBody.id = "packageBody";
    packageBody.style.width = "30px";
    packageBody.style.height = "5px";
    packageBody.style.position = "absolute";
    packageBody.style.top = `0px`;
    packageBody.style.left = `${coordinates}px`;
    packageBody.style.transformStyle = "preserve-3d";
    return packageBody;
  }

  createPackageBackM5(index, showScore) {
    const scores = {
      1: "carLeftNumberL",
      3: "carLeftNumberR",
      5: "carRightNumberL",
      7: "carRightNumberR",
    };

    const packageImages = {
      1: "carLeftPackageL",
      3: "carLeftPackageR",
      5: "carRightPackageL",
      7: "carRightPackageR",
    };

    const packageBack = document.createElement("div");
    packageBack.style.width = "30px";
    packageBack.style.height = "30px";
    packageBack.style.position = "absolute";
    packageBack.style.top = "5px";
    packageBack.style.transform = "rotateX(90deg)";
    packageBack.style.transformOrigin = "top";

    const packageImage = document.createElement("img");
    packageImage.id = "packageImage";
    const packageName = packageImages[index];
    packageImage.src = `assets/images/packages/${this.trialConfig[packageName]}`;
    packageImage.alt = `package${index} image`;
    packageImage.style.width = "30px";
    packageImage.style.height = "30px";
    packageImage.style.transform = "rotateX(180deg)";
    packageImage.style.verticalAlign = "bottom";

    packageBack.appendChild(packageImage);

    if (showScore) {
      const tempScore = scores[index];
      const packageScore = document.createElement("div");
      packageScore.id = `packageBody${index}Score`;
      packageScore.className = "driverScore";
      packageScore.innerText = this.trialConfig[tempScore];
      packageScore.style.fontSize = "32px";
      packageScore.style.color = "#512888";
      packageScore.style.paddingBottom = "20px";
      packageBack.appendChild(packageScore);
    }
    return packageBack;
  }
}
