import preloadResponse from "@jspsych/plugin-preload";

import { p } from "../../lib/markup/tags";
import { LANGUAGE } from "../../config/main";

export const preloadTrial = {
  type: preloadResponse,
  data: { name: "preloadResources" },
  message: p(LANGUAGE.prompts.settingUp),
  // show_detailed_errors: true,
  // TODO: We're definitely going to need to split up the images
  images: [
    // Driver images
    "assets/images/driver/collisionDriver.svg",
    "assets/images/driver/defaultDriver.svg",
    "assets/images/driver/happyDriver.svg",
    "assets/images/driver/sadDriver.svg",
    "assets/images/driver/surprisedDriver.svg",

    // Emotes
    "assets/images/emotes/emote-angry.svg",
    "assets/images/emotes/emote-disgust.svg",
    "assets/images/emotes/emote-happy.svg",
    "assets/images/emotes/emote-sad.svg",
    "assets/images/emotes/emote-surprise.svg",
    "assets/images/emotes/emote-trust.svg",

    // Headquarter
    "assets/images/headquarter/headquarterSurveyBanner.svg",
    "assets/images/headquarter/HQmessage_ruleSwitch.svg",
    "assets/images/headquarter/HQmessage_probabFeedback.svg",
    "assets/images/headquarter/HQmessage_reminderSpeed.svg",
    "assets/images/headquarter/HQmessageM1m0_introKeys.svg",
    "assets/images/headquarter/HQmessageM1m0_targetLocking.svg",
    "assets/images/headquarter/HQmessageM1m1_reminderSpacebar.svg",
    "assets/images/headquarter/HQmessageM1m2_practiceStart.svg",
    "assets/images/headquarter/HQmessageM1m3_practiceEnd.svg",
    "assets/images/headquarter/HQmessageM2m0.svg",
    "assets/images/headquarter/HQmessageM3m0.svg",
    "assets/images/headquarter/HQmessageM4m0.svg",
    "assets/images/headquarter/HQmessageM4m1.svg",
    "assets/images/headquarter/HQmessageM4m2.svg",
    "assets/images/headquarter/HQmessageM5m2.svg",
    "assets/images/headquarter/HQmessageM5m3.svg",

    // Mafia Icons
    "assets/images/mafiaIcons/iconBrightnessMafia.svg",
    "assets/images/mafiaIcons/iconLetterMafia.svg",
    "assets/images/mafiaIcons/iconNumberMafia.svg",

    // HUD
    "assets/images/hud/bomb_1.svg",
    "assets/images/hud/bomb_2.svg",
    "assets/images/hud/bomb_3.svg",
    "assets/images/hud/bomb_4.svg",
    "assets/images/hud/bomb_5.svg",
    "assets/images/hud/bomb_6.svg",
    "assets/images/hud/bomb_7.svg",
    "assets/images/hud/bomb_8.svg",
    "assets/images/hud/GasAndLabelsGeneric.svg",
    "assets/images/hud/heart-empty.svg",
    "assets/images/hud/heart-full.svg",
    "assets/images/hud/motorcycle-dashboard.svg",
    "assets/images/hud/SpeedAndLabelsGeneric.svg",
    "assets/images/hud/speed-needle.svg",

    // Instructions (Start Procedure)
    "assets/images/instructions/before_instructions_setEnvironment_1.svg",
    "assets/images/instructions/before_instructions_setEnvironment_2.svg",
    "assets/images/instructions/before_instructions1_mode_selection.svg",

    // Instructions (Mission 1)
    "assets/images/instructions/m1/instructions 1.01.svg",
    "assets/images/instructions/m1/instructions 1.02.svg",
    "assets/images/instructions/m1/instructions 1.03.svg",
    "assets/images/instructions/m1/instructions 1.04.svg",
    "assets/images/instructions/m1/instructions 1.05.svg",
    "assets/images/instructions/m1/instructions 1.06.svg",
    "assets/images/instructions/m1/instructions 1.07.svg",
    "assets/images/instructions/m1/instructions 1.08.svg",
    "assets/images/instructions/m1/instructions 1.09.svg",
    "assets/images/instructions/m1/instructions 1.10.svg",
    "assets/images/instructions/m1/instructions 1.11.svg",
    "assets/images/instructions/m1/instructions 1.12.svg",
    "assets/images/instructions/m1/instructions 1.13.svg",

    // Instructions (Mission 2)
    "assets/images/instructions/m2/instructions 2.1.svg",
    "assets/images/instructions/m2/instructions 2.2.svg",
    "assets/images/instructions/m2/instructions 2.3.svg",
    "assets/images/instructions/m2/instructions 2.4.svg",
    "assets/images/instructions/m2/instructions 2.5.svg",
    "assets/images/instructions/m2/instructions 2.6.svg",

    // Instructions (Mission 3)
    "assets/images/instructions/m3/instructions 3.1.svg",
    "assets/images/instructions/m3/instructions 3.2.svg",
    "assets/images/instructions/m3/instructions 3.3.svg",
    "assets/images/instructions/m3/instructions 3.4.svg",
    "assets/images/instructions/m3/instructions 3.5.svg",
    "assets/images/instructions/m3/instructions 3.6.svg",

    // Instructions (Mission 4)
    "assets/images/instructions/m4/instructions 4.1.svg",
    "assets/images/instructions/m4/instructions 4.2.svg",
    "assets/images/instructions/m4/instructions 4.3.svg",
    "assets/images/instructions/m4/instructions 4.4.svg",
    "assets/images/instructions/m4/instructions 4.5.svg",
    "assets/images/instructions/m4/instructions 4.6.svg",
    "assets/images/instructions/m4/instructions 4.7.svg",
    "assets/images/instructions/m4/instructions 4.8.svg",
    "assets/images/instructions/m4/instructions 4.9.svg",
    "assets/images/instructions/m4/instructions 4.10.svg",

    // Instructions (Mission 5)
    "assets/images/instructions/m5/instructions 5.1.svg",
    "assets/images/instructions/m5/instructions 5.2.svg",
    "assets/images/instructions/m5/instructions 5.3.svg",
    "assets/images/instructions/m5/instructions 5.4.svg",
    "assets/images/instructions/m5/instructions 5.5.svg",
    "assets/images/instructions/m5/instructions 5.6.svg",
    "assets/images/instructions/m5/instructions 5.7.svg",

    // Packages
    "assets/images/packages/package_medium.svg",
    "assets/images/packages/package_high.svg",
    "assets/images/packages/package_low.svg",
    "assets/images/packages/package_1star.svg",
    "assets/images/packages/package_2star.svg",
    "assets/images/packages/package_3star.svg",
    "assets/images/packages/escapePackage_1star.svg",
    "assets/images/packages/escapePackage_2star.svg",
    "assets/images/packages/escapePackage_3star.svg",

    // Postgame
    "assets/images/postgame/carSortBackground.svg",
    "assets/images/postgame/FeedbackScreen_Mission1.svg",
    "assets/images/postgame/FeedbackScreen_Mission2.svg",
    "assets/images/postgame/FeedbackScreen_Mission3.svg",
    "assets/images/postgame/FeedbackScreen_Mission4.svg",
    "assets/images/postgame/FeedbackScreen_Mission5.svg",
    "assets/images/postgame/packageSortBackground.svg",
    "assets/images/postgame/postgame 2.svg",
    "assets/images/postgame/postgame 7.svg",
    "assets/images/postgame/postgame 8.svg",
    "assets/images/postgame/postgame 13.svg",
    "assets/images/postgame/CreditsScreen.svg",
    "assets/images/postgame/achievementScreen_mission1.svg",
    "assets/images/postgame/achievementScreen_mission2.svg",
    "assets/images/postgame/achievementScreen_mission3.svg",
    "assets/images/postgame/achievementScreen_mission4.svg",
    "assets/images/postgame/achievementScreen_mission5.svg",

    // Target cars
    "assets/images/targetCars/target_blurred.svg",
    "assets/images/targetCars/target_flat_blue_sad.svg",
    "assets/images/targetCars/target_flat_green_neutral.svg",
    "assets/images/targetCars/target_flat_yellow_happy.svg",
    "assets/images/targetCars/target_round_blue_sad.svg",
    "assets/images/targetCars/target_round_green_neutral.svg",
    "assets/images/targetCars/target_round_yellow_happy.svg",
    "assets/images/targetCars/target_tall_blue_sad.svg",
    "assets/images/targetCars/target_tall_green_neutral.svg",
    "assets/images/targetCars/target_tall_yellow_happy.svg",

    // Target cars (m4)
    "assets/images/targetCars/target_flat_blue_sad_modelA.svg",
    "assets/images/targetCars/target_flat_blue_sad_modelB.svg",
    "assets/images/targetCars/target_flat_blue_sad_modelC.svg",
    "assets/images/targetCars/target_flat_green_neutral_modelA.svg",
    "assets/images/targetCars/target_flat_green_neutral_modelB.svg",
    "assets/images/targetCars/target_flat_green_neutral_modelC.svg",
    "assets/images/targetCars/target_flat_yellow_happy_modelA.svg",
    "assets/images/targetCars/target_flat_yellow_happy_modelB.svg",
    "assets/images/targetCars/target_flat_yellow_happy_modelC.svg",
    "assets/images/targetCars/target_round_blue_sad_modelA.svg",
    "assets/images/targetCars/target_round_blue_sad_modelB.svg",
    "assets/images/targetCars/target_round_blue_sad_modelC.svg",
    "assets/images/targetCars/target_round_green_neutral_modelA.svg",
    "assets/images/targetCars/target_round_green_neutral_modelB.svg",
    "assets/images/targetCars/target_round_green_neutral_modelC.svg",
    "assets/images/targetCars/target_round_yellow_happy_modelA.svg",
    "assets/images/targetCars/target_round_yellow_happy_modelB.svg",
    "assets/images/targetCars/target_round_yellow_happy_modelC.svg",
    "assets/images/targetCars/target_tall_blue_sad_modelA.svg",
    "assets/images/targetCars/target_tall_blue_sad_modelB.svg",
    "assets/images/targetCars/target_tall_blue_sad_modelC.svg",
    "assets/images/targetCars/target_tall_green_neutral_modelA.svg",
    "assets/images/targetCars/target_tall_green_neutral_modelB.svg",
    "assets/images/targetCars/target_tall_green_neutral_modelC.svg",
    "assets/images/targetCars/target_tall_yellow_happy_modelA.svg",
    "assets/images/targetCars/target_tall_yellow_happy_modelB.svg",
    "assets/images/targetCars/target_tall_yellow_happy_modelC.svg",

    "assets/images/targetCars/target_precue_number.svg",
    "assets/images/targetCars/target_precue_letter.svg",
    "assets/images/targetCars/target_precue_brightness.svg",

    // Context Cues (m4)
    "assets/images/contextCues/contextCue_cloud.png",
    "assets/images/contextCues/contextCue_sun.png",

    // Trucks (m4)
    "assets/images/truck/truck_flat_blue_sad.svg",
    "assets/images/truck/truck_flat_green_neutral.svg",
    "assets/images/truck/truck_flat_pink_sad.svg",
    "assets/images/truck/truck_flat_yellow_happy.svg",
    "assets/images/truck/truck_round_blue_sad.svg",
    "assets/images/truck/truck_round_green_neutral.svg",
    "assets/images/truck/truck_round_pink_sad.svg",
    "assets/images/truck/truck_round_yellow_happy.svg",
    "assets/images/truck/truck_tall_blue_sad.svg",
    "assets/images/truck/truck_tall_green_neutral.svg",
    "assets/images/truck/truck_tall_pink_sad.svg",
    "assets/images/truck/truck_tall_yellow_happy.svg",

    // Trucks (m2)
    "assets/images/truck/truck_flat_orange_happy.svg",
    "assets/images/truck/truck_flat_purple_neutral.svg",
    "assets/images/truck/truck_flat_red_sad.svg",
    "assets/images/truck/truck_round_orange_happy.svg",
    "assets/images/truck/truck_round_purple_neutral.svg",
    "assets/images/truck/truck_round_red_sad.svg",
    "assets/images/truck/truck_tall_orange_happy.svg",
    "assets/images/truck/truck_tall_purple_neutral.svg",
    "assets/images/truck/truck_tall_red_sad.svg",

    // Mode selection
    "assets/images/mode_selection.svg",

    // Truck Propose Lane
    "assets/images/truckProposesLane/truckProposesLane1.png",
    "assets/images/truckProposesLane/truckProposesLane2.png",
    "assets/images/truckProposesLane/truckProposesLane3.png",
    "assets/images/truckProposesLane/truckProposesLane5.png",
    "assets/images/truckProposesLane/truckProposesLane6.png",
    "assets/images/truckProposesLane/truckProposesLane7.png",
    "assets/images/truckProposesLane/truckProposesLaneX.png",

    //Mission 5 Gang Cars
    "assets/images/gangCars/gang_high.svg",
    "assets/images/gangCars/gang_medium.svg",
    "assets/images/gangCars/gang_low.svg",
  ],
  audio: [
    "assets/audio/CG01_01_LockOn_V1.wav",
    "assets/audio/CG01_05_Crash_V1.wav",
    "assets/audio/CG01_06_Message_V1.wav",
    "assets/audio/CG01_07_Package_V1.wav",
    "assets/audio/CG01_08_Trucks_V1.wav",
    "assets/audio/CG01_09_ShiftLane_V1.wav",
    "assets/audio/CG01_10_Celebrate_V1.wav",
    "assets/audio/CG01_11_LicencePlate_V1.wav",
    "assets/audio/happy.wav",
    "assets/audio/sad.wav",
    "assets/audio/surprised.wav",
  ],
};
