import jsPsychInstructionsResponse from "@jspsych/plugin-instructions";

import { div, h1, image } from "../../lib/markup/tags";
import { instructionImage } from "../../lib/utils";
import { buildQuizInstructions } from "./instructions";
import { buildFreeSortQuizTrial } from "./freeSortQuiz";

/**
 * Displays the mission feedback screen as an instructions trial
 * @param {Score} score The score instance used to keep track of the score for the mission
 * @param {string} backgroundImage The background image of the feedback screen
 */
export function buildFeedbackTrial(score, backgroundImage) {
  return {
    type: jsPsychInstructionsResponse,
    data: { name: "feedbackScreen" },
    key_forward: " ",
    key_backward: "z",
    pages: () => {
      const { missionReward, missionPackages, missionCrashes } = score.getScore();
      return [
        // NOTE: HTML must match the structure of the feedbackScreen class
        div(
          instructionImage(backgroundImage) +
            div(
              div(
                h1("Total Rewards:", { class: "rewardLabel" }) +
                  div(h1(missionReward) + image({ src: "assets/images/hud/star.svg" }), {
                    class: "rewardValue",
                  }),
                { class: "rewardContainer" }
              ) +
                div(
                  h1("Total Packages:", { class: "rewardLabel" }) +
                    div(
                      h1(missionPackages) +
                        image({ src: "assets/images/packages/package_medium.svg" }),
                      { class: "rewardValue" }
                    ),
                  { class: "rewardContainer" }
                ) +
                div(
                  h1("Total Crashes:", { class: "rewardLabel" }) +
                    div(
                      h1(missionCrashes) +
                        image({ src: "assets/images/driver/collisionDriver.svg" }),
                      { class: "rewardValue" }
                    ),
                  { class: "rewardContainer" }
                ),
              { class: "feedbackContainer" }
            ),
          { class: "feedbackScreen" }
        ),
      ];
    },
    on_finish: (data) => score.assignScore(data),
  };
}

/**
 * Builds the procedure for the first free-sort quiz
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Score} score The Score instance used to keep track of the user's score within the mission
 */
export function buildQuizOneProcedure(jsPsych, score) {
  return {
    timeline: [
      // Pre-instructions
      buildQuizInstructions(jsPsych, score, ["assets/images/postgame/postgame 2.svg"]),
      // First quiz
      buildFreeSortQuizTrial(jsPsych, score, "assets/images/postgame/packageSortBackground.svg", [
        "assets/images/packages/package_medium.svg",
        "assets/images/packages/package_high.svg",
        "assets/images/packages/package_low.svg",
      ]),
      // TODO: uncomment out if answer key wanted after quiz1
      // // Post-instructions (answer key)
      // buildQuizInstructions(jsPsych, score, ["assets/images/postgame/postgame 7.svg"]),
    ],
    key_forward: "/",
    key_backward: "z",
  };
}

/**
 * Builds the procedure for the first free-sort quiz
 * @param {JsPsych} jsPsych jsPsych instance being used to run the task
 * @param {Score} score The Score instance used to keep track of the user's score within the mission
 */
export function buildQuizTwoProcedure(jsPsych, score) {
  return {
    timeline: [
      buildQuizInstructions(jsPsych, score, ["assets/images/postgame/postgame 8.svg"]),
      // Quiz
      buildFreeSortQuizTrial(jsPsych, score, "assets/images/postgame/carSortBackground.svg", [
        "assets/images/targetCars/target_flat_blue_sad.svg",
        "assets/images/targetCars/target_flat_green_neutral.svg",
        "assets/images/targetCars/target_flat_yellow_happy.svg",
        "assets/images/targetCars/target_round_blue_sad.svg",
        "assets/images/targetCars/target_round_green_neutral.svg",
        "assets/images/targetCars/target_round_yellow_happy.svg",
        "assets/images/targetCars/target_tall_blue_sad.svg",
        "assets/images/targetCars/target_tall_green_neutral.svg",
        "assets/images/targetCars/target_tall_yellow_happy.svg",
      ]),
      // Post-instructions (answer key)
      // TODO: uncomment out if answer key wanted after quiz2
      // buildQuizInstructions(jsPsych, score, ["assets/images/postgame/postgame 13.svg"]),
    ],
    key_forward: "/",
    key_backward: "z",
  };
}
